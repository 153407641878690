import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './QuestionCreateForm.css';

const QuestionCreateForm = () => {
  const initialQuestionState = {
    text: '',
    type: 'MCQ',
    choices: [],
    correct_answer: [], // Modified to handle an array of correct answers
    marks: 1,
    difficulty: 0.5,
    discrimination: 1.0,
    explanation: '',
    video: '',
    guessing_parameter: 0.25,
    course: '',
    topics: '',
    image: null,
  };

  const [question, setQuestion] = useState(initialQuestionState);
  const [questionList, setQuestionList] = useState([]);
  const [choiceText, setChoiceText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [successes, setSuccesses] = useState([]);
  const [failures, setFailures] = useState([]);
  const [questionsFromAPI, setQuestionsFromAPI] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [choicesForQuestions, setChoicesForQuestions] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Fetch the list of questions from the backend when the component mounts
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('/api/questions/');
        setQuestionsFromAPI(response.data.questions);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      [name]: value,
    }));
  };

  const handleChoiceChange = (e) => {
    setChoiceText(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          image: reader.result, // Base64 string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const addChoice = () => {
    if (choiceText.trim() !== '') {
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        choices: [...prevQuestion.choices, { text: choiceText }],
      }));
      setChoiceText('');
    }
  };

  const handleCorrectAnswerChange = (e) => {
    const value = e.target.value.trim(); // Ensure no spaces around the value

    if (question.type === 'MCQA') {
      // Handle multiple correct answers
      const isChecked = e.target.checked;
      if (isChecked) {
        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          correct_answer: [...prevQuestion.correct_answer, value],
        }));
      } else {
        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          correct_answer: prevQuestion.correct_answer.filter((answer) => answer !== value),
        }));
      }
    } else {
      // Handle single correct answer
      setQuestion((prevQuestion) => ({
        ...prevQuestion,
        correct_answer: [value],
      }));
    }
  };

  const addQuestionToList = () => {
    setQuestionList([...questionList, question]);
    setQuestion(initialQuestionState);
  };

  const handleSubmitAll = async () => {
    setSubmitting(true);

    const formattedQuestions = questionList.map((q) => ({
        ...q,
        choices: q.choices.map((choice) => ({ text: choice.text })), // Mapping choices correctly
        topics: q.topics.split(',').map((id) => parseInt(id, 10)), // Converting topic IDs to integers
        correct_answer: q.correct_answer.join(', '), // Join multiple answers with a space after each comma
    }));

    try {
        const response = await axios.post('/api/questions/create', formattedQuestions, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        setSuccesses(response.data.successes);
        setFailures(response.data.failures);
        setQuestionList([]);
    } catch (error) {
        console.error('Error creating questions:', error);
        setFailures([...failures, { message: error.message }]);
    }

    setSubmitting(false);
};

  const handleQuestionSelect = async (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    const selectedIds = selectedOptions.map(option => parseInt(option.value, 10));
    const selected = questionsFromAPI.filter(q => selectedIds.includes(q.id));
    setSelectedQuestions(selected);

    // Fetch choices for selected questions
    const newChoicesForQuestions = { ...choicesForQuestions };
    for (let question of selected) {
      try {
        const response = await axios.get(`/api/get_question_choices/${question.id}`);
        newChoicesForQuestions[question.id] = response.data.choices;
      } catch (error) {
        console.error('Error fetching choices:', error);
      }
    }
    setChoicesForQuestions(newChoicesForQuestions);
  };

  const handleImageUpload = async (e, id, type) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      if (type === 'question') {
        formData.append('question_image', file);
      } else if (type === 'choice') {
        formData.append('choice_image', file);
      }

      try {
        await axios.post(`/api/add_question_images/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image.');
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredQuestions = questionsFromAPI.filter((q) =>
    q.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="question-form-container">
      <h1 className="form-title">Create a New Question</h1>
      {/* Top Form for Adding Questions */}
      <form className="question-form">
        <label>Question Text:</label>
        <textarea
          name="text"
          value={question.text}
          onChange={handleInputChange}
          className="form-textarea"
        />

        <label>Question Type:</label>
        <select name="type" value={question.type} onChange={handleInputChange} className="form-select">
          <option value="MCQ">Multiple Choice</option>
          <option value="SA">Short Answer</option>
          <option value="MCQA">Multiple Choice Multiple Answers</option>
        </select>

        <div>
          <label>Choices:</label>
          {question.choices.map((choice, index) => (
            <div key={index}>{choice.text}</div>
          ))}
          <input type="text" value={choiceText} onChange={handleChoiceChange} />
          <button type="button" onClick={addChoice}>
            Add Choice
          </button>
        </div>

        <label>Correct Answer:</label>
        {question.type === 'MCQA' ? (
          <div>
            {question.choices.map((choice, index) => (
              <div key={index}>
                <label>
                  <input
                    type="checkbox"
                    value={choice.text}
                    checked={question.correct_answer.includes(choice.text)}
                    onChange={handleCorrectAnswerChange}
                  />
                  {choice.text}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <select
            name="correct_answer"
            value={question.correct_answer[0] || ""}
            onChange={handleCorrectAnswerChange}
            className="form-select"
          >
            <option value="">Select the correct answer</option>
            {question.choices.map((choice, index) => (
              <option key={index} value={choice.text}>
                {choice.text}
              </option>
            ))}
          </select>
        )}

        <label>Marks:</label>
        <input type="number" name="marks" value={question.marks} onChange={handleInputChange} />

        <label>Difficulty:</label>
        <input type="number" step="0.01" name="difficulty" value={question.difficulty} onChange={handleInputChange} />

        <label>Discrimination:</label>
        <input type="number" step="0.01" name="discrimination" value={question.discrimination} onChange={handleInputChange} />

        <label>Explanation:</label>
        <input type="text" name="explanation" value={question.explanation} onChange={handleInputChange} />

        <label>Video URL:</label>
        <input type="url" name="video" value={question.video} onChange={handleInputChange} />

        <label>Guessing Parameter:</label>
        <input type="number" step="0.01" name="guessing_parameter" value={question.guessing_parameter} onChange={handleInputChange} />

        <label>Course ID:</label>
        <input type="text" name="course" value={question.course} onChange={handleInputChange} />

        <label>Topics (comma-separated IDs):</label>
        <input type="text" name="topics" value={question.topics} onChange={handleInputChange} />

      

        <button type="button" onClick={addQuestionToList} className="add-question-button">
          Add Question to List
        </button>
      </form>

      <div className="question-list">
        <h2>Questions to be Submitted:</h2>
        {questionList.map((q, index) => (
          <div key={index} className="listed-question">
            {q.text} - {q.type}
          </div>
        ))}
      </div>

      <button onClick={handleSubmitAll} className="submit-all-button" disabled={submitting || questionList.length === 0}>
        {submitting ? 'Submitting All Questions...' : 'Submit All Questions'}
      </button>

      {/* Bottom Form for Adding Images to Existing Questions and Choices */}
      <div className="existing-questions-container">
        <h2>Add Images to Existing Questions and Choices</h2>
        <input
          type="text"
          placeholder="Search questions..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
        <select
          multiple
          onChange={handleQuestionSelect}
          className="scrollable-question-list"
          style={{ height: '200px', width: '100%', overflowY: 'scroll' }}
        >
          <option value="">Select one or more questions</option>
          {filteredQuestions.map((q) => (
            <option key={q.id} value={q.id}>
              {q.text}
              {q.correct_answer}

            </option>
          ))}
        </select>

        {selectedQuestions.map((question) => (
          <div key={question.id} className="image-upload-container">
            <h3>{question.text}</h3>
            <input
              type="file"
              onChange={(e) => handleImageUpload(e, question.id, 'question')}
            />
            {choicesForQuestions[question.id] && (
              <div className="choices-list">
                <h4>Choices:</h4>
                <ul>
                  {choicesForQuestions[question.id].map((choice) => (
                    <li key={choice.id}>
                      
                      {choice.text}
                      <input
                        type="file"
                        onChange={(e) => handleImageUpload(e, choice.id, 'choice')}
                      />
                    </li>
                  ))}
                </ul>
                <h2>Correct Answer to the question</h2>
                      <p>{question.correct_answer}</p>
              <h2>Topic</h2>

              </div>
            )}
          </div>
        ))}
      </div>

      <div className="form-feedback">
        {successes.length > 0 && (
          <div className="success-messages">
            <h3>Successes:</h3>
            {successes.map((success, index) => (
              <div key={index}>{success.text} created successfully.</div>
            ))}
          </div>
        )}
        {failures.length > 0 && (
          <div className="error-messages">
            <h3>Failures:</h3>
            {failures.map((failure, index) => (
              <div key={index}>{failure.message}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionCreateForm;
