import React, { useEffect } from "react";
import "./InteractiveLabs.css"; // Importing the CSS file
import "./Arcade.css"; // Import the dedicated Arcade CSS
import useSound from "../Sound";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

// Google Analytics Initialization
const TRACKING_ID = "G-1XD9QJLJGR"; // Replace with your GA4 Measurement ID
ReactGA.initialize(TRACKING_ID);

function Arcade() {
    const { play } = useSound("/ui-click-43196.mp3"); // Local file path
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate(); // Hook to programmatically navigate
    const location = useLocation(); // Hook to track current location

    // Track page views on component mount & route changes
    useEffect(() => {
        if (location.pathname) {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        }
    }, [location.pathname]);

    const handleSound = () => {
        play();
    };

    const handleAccess = (path) => {
        play();
        ReactGA.event({
            category: "Navigation",
            action: "Clicked on a Game",
            label: path,
        });
        navigate(path);
    };

    return (
        <div className="outer-lab-container">
            <div className="lab-container">
                <h1 className="lab-title">Educational Games</h1>
                <div className="lab-sections">
                    <div className="lab-section">
                        <h2>Biochemistry Games</h2>
                        <ul className="lab-list">
                            <li className="lab-link" onClick={() => handleAccess("/Biochem")}>
                                <a>
                                    <img
                                        src="image/pathways.jpg"
                                        alt="Nomenclature Diagram"
                                        style={{ width: "80%", height: "auto" }}
                                    />
                                    How much do you know about Biochemical Mechanisms
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lab-section">
                        <h2>Organic Chemistry Games</h2>
                        <ul className="lab-list">
                            <li className="lab-link" onClick={() => handleAccess("/nomenclature")}>
                                <a>
                                    <img
                                        src="image/nomenclature.png"
                                        alt="Nomenclature Diagram"
                                        style={{ width: "80%", height: "auto" }}
                                    />
                                    Guess the IUPAC name!
                                </a>
                            </li>
                            <li className="lab-link" onClick={() => handleAccess("/stereocentergame")}>
                                <a>
                                    <img
                                        src="image/R_S.png"
                                        alt="Nomenclature Diagram"
                                        style={{ width: "80%", height: "auto" }}
                                    />
                                    Guess the stereocenter of the carbon!
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lab-section">
                        <h2>Physics Games</h2>
                        <ul className="lab-list">
                            <li className="lab-link" onClick={() => handleAccess("/Kinematics")}>
                                <a>
                                    <img
                                        src="image/kinetics.jpg"
                                        alt="Physics Kinetics"
                                        style={{ width: "80%", height: "auto" }}
                                    />
                                    Solve the Kinematics Problem!
                                </a>
                            </li>
                            <li className="lab-link" onClick={() => handleAccess("/Electrostatics")}>
                                <a>
                                    <img
                                        src="image/electrostatics.png"
                                        alt="Nomenclature Diagram"
                                        style={{ width: "80%", height: "auto" }}
                                    />{" "}
                                    <br />
                                    How much do you know about electrostatics?!
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="lab-section">
                        <h2>Pharmacology Games!</h2>
                        <ul className="lab-list">
                            <li className="lab-link" onClick={() => handleAccess("/DrugDiscoveryGame")}>
                                <a>
                                    Do you remember the concepts of drug discovery?
                                </a>
                            </li>
                            <li className="lab-link" onClick={() => handleAccess("/DosageGame")}>
                                <a>
                                    <img
                                        src="image/Dosage.png"
                                        alt="Drugs"
                                        style={{ width: "80%", height: "auto" }}
                                    />{" "}
                                    <br />
                                    Let's do some Dosage Questions!
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Arcade;
