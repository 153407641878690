import React, { useState, useEffect } from 'react';
import './MoleculeViewer.css';

const MoleculeViewer2 = () => {
    const queryParams = new URLSearchParams(window.location.search);

    const [isPhone, setIsPhone] = useState(window.innerWidth < 600);
    const [height, setHeight] = useState(queryParams.get('height') || (isPhone ? '300px' : '800px'));
    const [width, setWidth] = useState(queryParams.get('width') || (isPhone ? '300px' : '800px'));
    const [pdb, setPdb] = useState(queryParams.get('pdb') || ''); // Default PDB code
    const [cid, setCid] = useState(queryParams.get('cid') || '');
    const [href, setHref] = useState(queryParams.get('href') || 'T_R_Hemoglobin.pdb');
    const [element, setElement] = useState(queryParams.get('element') || '');
    const [type, setType] = useState(queryParams.get('type') || 'pdb');
    const [backgroundColor, setBackgroundColor] = useState(queryParams.get('backgroundColor') || 'black');
    const [backgroundAlpha, setBackgroundAlpha] = useState(queryParams.get('backgroundAlpha') || '1.0');
    
    // Ensure hetflag is TRUE to display Heme group
    const [select, setSelect] = useState(queryParams.get('select') || '{"hetflag":true}');
    
    // Custom style for cartoon representation + heme group
    const [style, setStyle] = useState(queryParams.get('style') || JSON.stringify({
        cartoon: { color: 'spectrum' },
        hetflag: { stick: { color: 'red' }, sphere: { radius: 0.8, color: 'green' } }
    }));

    const [surface, setSurface] = useState(queryParams.get('surface') || '');
    const [labelRes, setLabelRes] = useState(queryParams.get('labelRes') || '');
    const [zoomTo, setZoomTo] = useState(queryParams.get('zoomTo') || '');
    const [spin, setSpin] = useState(queryParams.get('spin') || '');
    const [ui, setUi] = useState(queryParams.get('ui') || 'true');

    useEffect(() => {
        const handleResize = () => {
            setIsPhone(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (queryParams.get('height')) {
            setHeight(queryParams.get('height') + 'px');
        } else {
            setHeight(isPhone ? '300px' : '800px');
        }

        if (queryParams.get('width')) {
            setWidth(queryParams.get('width') + 'px');
        } else {
            setWidth(isPhone ? '300px' : '800px');
        }
    }, [isPhone, queryParams]);

    const handleApplyChanges = () => {
        const params = new URLSearchParams({
            height: parseInt(height),
            width: parseInt(width),
            pdb,
            cid,
            href,
            element,
            type,
            backgroundColor,
            backgroundAlpha,
            select,
            style,
            surface,
            labelRes,
            zoomTo,
            spin,
            ui,
        });
        window.location.search = params.toString();
    };

    const viewerStyle = {
        height,
        width,
        position: 'relative'
    };

    return (
        <div>
            <center>
                <h2>Welcome to the MoleculeViewer</h2>
                <p>
                    This interactive tool allows you to explore molecular structures in **3D** using 3Dmol.js.
                </p>

                <div className="controls-container">
                    <label> Background Color:
                        <input type="text" value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)} />
                    </label>
                    <label> Show Heme Group:
                        <select value={select} onChange={(e) => setSelect(e.target.value)}>
                            <option value='{"hetflag":false}'>Hide Ligands</option>
                            <option value='{"hetflag":true}'>Show Ligands (Heme Group)</option>
                        </select>
                    </label>
                    <button onClick={handleApplyChanges}>Apply Changes</button>
                </div>

                {/* 3Dmol.js Viewer */}
                <div style={viewerStyle} className='viewer_3Dmoljs' 
                     data-href={href}
                     data-type={type}
                     data-backgroundcolor={backgroundColor}
                     data-backgroundalpha={backgroundAlpha}
                     data-select={select}
                     data-style={style}
                     data-zoomto={zoomTo}
                     data-spin={spin}
                     data-ui={ui}>
                </div>
            </center>
        </div>
    );
};

export default MoleculeViewer2;
