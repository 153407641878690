// CATAssessmentPage.js
import React, { useState, useEffect } from 'react';
import api from './api';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import '../assessmentpage.css';
import AbilityGraph from './AbilityGraph';
import useSound from '../Sound';

function CATAssessmentPage() {
    const { assessmentId } = useParams();
    const navigate = useNavigate();
    const access_token = useSelector(state => state.userLogin.userInfo.access);
    const [abilityScore, setAbilityScore] = useState(0);
    const [confidence, setConfidence] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [userAnswer, setUserAnswer] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [assessmentResponseId, setAssessmentResponseId] = useState(null);
    const [showImage, setShowImage] = useState(false);
    const [awsImageUrl, setAwsImageUrl] = useState("");
    const [questionCount, setQuestionCount] = useState(0);
    const [timeTaken, setTimeTaken] = useState(0); // State to track time for current question
    const [timerInterval, setTimerInterval] = useState(null); // Reference to the timer interval
    const [timePerQuestion, setTimePerQuestion] = useState([]); // State to store time taken for each question
    const {play,stop,loop} = useSound('/ui-click-43196.mp3'); // Local file path

    const updateScores = (newScore, newConfidence) => {
        setAbilityScore(newScore);
        setConfidence(newConfidence);
        setShowImage(false);
    };

    const startTimer = () => {
        setTimeTaken(0);
        if (timerInterval) clearInterval(timerInterval);
        const interval = setInterval(() => {
            setTimeTaken(prevTime => prevTime + 1);
        }, 1000);
        setTimerInterval(interval);
    };

    const stopTimer = () => {
        if (timerInterval) clearInterval(timerInterval);
    };

    const handleEndTestEarly = () => {
        fetchNextQuestion(true); // Pass `true` to indicate ending the test early
        stopTimer(); // Stop the timer
    };

    const fetchNextQuestion = async (endTestEarly = false) => {
        setShowImage(false);
        try {
            const url = `/api/assessments/${assessmentId}/next_question/${endTestEarly ? '?ended=true' : ''}`;
            const response = await api.get(url, {
                headers: { Authorization: `Bearer ${access_token}` }
            });

            if (response.data.is_completed) {
                setIsCompleted(true);
                setAssessmentResponseId(response.data.assessment_response_id);
                stopTimer();
            } else {
                setCurrentQuestion(response.data);
                if (response.data.type === 'MCQA') {
                    setUserAnswer([]);
                } else {
                    setUserAnswer(null);
                }
                setQuestionCount(prevCount => prevCount + 1);
                startTimer(); // Start the timer when a new question is loaded
            }
        } catch (error) {
            console.error('Error fetching next question:', error);
        }
    };

    const handleSubmitAnswer = async () => {
        try {
            let answer;
            if (currentQuestion.type === 'MCQA') {
                answer = currentQuestion.choices
                    .filter(choice => userAnswer.includes(choice.id))
                    .map(choice => choice.text)
                    .join(', ')
                    .replace(/\.$/, '');
                play();
            } else if (currentQuestion.type === 'MCQ') {
                const selectedChoice = currentQuestion.choices.find(choice => choice.id === userAnswer);
                answer = selectedChoice ? selectedChoice.text : '';
                play();
            } else {
                answer = userAnswer;
                play();
            }

            // Append time taken for current question to timePerQuestion array
            setTimePerQuestion(prevTimes => [...prevTimes, timeTaken]);

            // Send time taken for the question to backend
            const response = await api.post(`/api/submit_question/${currentQuestion.question_response_id}/`, {
                user_answer: answer,
                time_taken: timeTaken // Send the time taken along with the answer
            }, { headers: { Authorization: `Bearer ${access_token}` } });

            const abilityScoreNumber = parseFloat(response.data.current_ability);
            const confidenceNumber = parseFloat(response.data.confidence);
            updateScores(abilityScoreNumber, confidenceNumber);
            stopTimer(); // Stop the timer when the answer is submitted

            if (confidenceNumber >= 0.95 || response.data.is_completed) {
                setShowImage(true);
                alert("Ada has noted your answers and is now calculating your final results. Click 'OK' to review your performance.");
                setIsCompleted(true);

                // Pass timePerQuestion to assessment review page
                navigate(`/assessment_review/${assessmentResponseId}`, {
                    state: { abilityScore, timePerQuestion, isCatBased: true }
                });
            } else {
                fetchNextQuestion();
            }
        } catch (error) {
            console.error('Error submitting answer:', error);
        }
    };

    useEffect(() => {
        fetchNextQuestion();
        play();
        return () => stopTimer(); // Cleanup the timer when the component unmounts
    }, [assessmentId]);

    useEffect(() => {
        if (isCompleted && assessmentResponseId) {
            navigate(`/assessment_review/${assessmentResponseId}`, { 
                state: { abilityScore, timePerQuestion, isCatBased: true } 
            });
        }
    }, [isCompleted, navigate, assessmentResponseId, abilityScore, timePerQuestion]);

    const handleMCQAnswerChange = (index) => {
        setUserAnswer(index);
    };

    const handleSAAnswerChange = (e) => {
        setUserAnswer(e.target.value);
    };

    const handleMCQAAnswerChange = (index) => {
        if (Array.isArray(userAnswer)) {
            const updatedAnswers = userAnswer.includes(index)
                ? userAnswer.filter(ans => ans !== index)
                : [...userAnswer, index];
            setUserAnswer(updatedAnswers);
        }
    };

    const renderQuestionChoices = () => {
        if (!currentQuestion) return null;

        const questionImage = currentQuestion.image ? (
            <img className="assessment-question-image" src={currentQuestion.image} alt="Question" />
        ) : null;

        const choices = currentQuestion.choices.map((choice, index) => (
            <div key={index} className="choices">
                {currentQuestion.type === 'MCQA' ? (
                    <>
                        <input
                            type="checkbox"
                            id={`choice-${choice.id}`}
                            name="answer"
                            checked={Array.isArray(userAnswer) && userAnswer.includes(choice.id)}
                            onChange={() => handleMCQAAnswerChange(choice.id)}
                        />
                        <label htmlFor={`choice-${choice.id}`}>
                            {choice.image && (
                                <img 
                                    className="assessment-choice-image" 
                                    src={choice.image} 
                                    alt={`Choice ${index + 1}`} 
                                />
                            )}
                            {choice.text}
                        </label>
                    </>
                ) : (
                    <>
                        <input
                            type="radio"
                            id={`choice-${choice.id}`}
                            name="answer"
                            value={choice.id}
                            checked={userAnswer === choice.id}
                            onChange={() => handleMCQAnswerChange(choice.id)}
                        />
                        <label htmlFor={`choice-${choice.id}`}>
                            {choice.image && (
                                <img 
                                    className="assessment-choice-image" 
                                    src={choice.image} 
                                    alt={`Choice ${index + 1}`} 
                                />
                            )}
                            {choice.text}
                        </label>
                    </>
                )}
            </div>
        ));

        return (
            <>
                {questionImage}
                <h3>{currentQuestion.text}</h3>
                {currentQuestion.type === 'SA' ? (
                    <input
                        type="text"
                        value={userAnswer || ''}
                        onChange={handleSAAnswerChange}
                    />
                ) : (
                    choices
                )}
            </>
        );
    };

    if (!currentQuestion) {
        return <div>Loading question or no more questions left...</div>;
    }

    return (
        <div className='cat-container-background'>
        <div className="cat-container">
            <div className="timer-display">
                Time: {Math.floor(timeTaken / 60)}:{String(timeTaken % 60).padStart(2, '0')} minutes
            </div>
            <p
                style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    margin: '10px 0',
                    color: '#333'
                }}
            >
                Question {questionCount}
            </p>
            {renderQuestionChoices()}
            <button className="cat-submit-button" onClick={handleSubmitAnswer}>Submit Answer</button>
            <button className="cat-end-test-button" onClick={handleEndTestEarly}>End Test Early</button>
            {showImage && (
                <div className="cat-image-container">
                    <img src={awsImageUrl} alt="Ada" />
                </div>
            )}
            <AbilityGraph abilityScore={abilityScore} key={abilityScore} />
            <br />
            <br />
            <br />
        </div>
        </div>
    );
}

export default CATAssessmentPage;
