import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { login } from '../actions/userActions';  
import FormContainer from './FormContainer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './LoginPage.css';

const LoginPage = ({history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.search ? location.search.split('=')[1] : '/';

  const userLogin = useSelector(state => state.userLogin);
  const { loading, error, userInfo } = userLogin; 

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      alert('Please fill in all fields');
      return;
    }
    
    setIsSubmitting(true);
    try {
      await dispatch(login(username, password));
    } catch (err) {
      console.error('Login error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate("/InteractiveLabs");
    }
  }, [userInfo, navigate, redirect]);
  
  return (
    <FormContainer>
      <div className="login-container">
        <h2 id="LoginForm">Login</h2>
        {loading && <div className="loading-spinner">Loading...</div>}
        {error && <div className="error-message">{error}</div>}
        
        <Form onSubmit={handleLogin} noValidate>
          <Form.Group controlId='username'>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
              required
              disabled={isSubmitting}
            />
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
              autoComplete="current-password"
              required
              disabled={isSubmitting}
            />
          </Form.Group>

          <Button 
            type='submit' 
            variant='primary'
            disabled={isSubmitting || loading}
            className="login-button"
          >
            {isSubmitting ? 'Logging in...' : 'Login'}
          </Button>
        </Form>

        <Row className='py-3'>
          <Col>
            New to the site?{' '}
            <Link 
              to={redirect ? `/Register?redirect=${redirect}` : '/Register'}
              className="register-link"
            >
              Register
            </Link>
          </Col>
        </Row>
      </div>
    </FormContainer>
  );
};
  
export default LoginPage;
