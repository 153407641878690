import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './assessmentreview.css';
import { useSelector } from 'react-redux';
import api from './api';

const RegularAssessmentReview = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assessmentreview, setAssessmentReview] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const { assessmentResponseId } = useParams(); // Get the ID from URL params
  
  // Get data from URL query parameters
  const getQueryParam = (name, defaultValue) => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has(name)) {
      const value = queryParams.get(name);
      // Try to parse as number if it looks like one
      if (!isNaN(value) && value !== '') return Number(value);
      return value;
    }
    return defaultValue;
  };
  
  // Get score and possible score from URL parameters
  const score = getQueryParam('score', 0);
  const possibleScore = getQueryParam('possible_score', 0);
  
  // Get authentication token from Redux store
  const userInfo = useSelector(state => state.userLogin.userInfo);
  const access_token = userInfo ? userInfo.access : null;
  
  useEffect(() => {
    const fetchReviewData = async () => {
      setLoading(true);
      try {
        if (!assessmentResponseId) {
          console.error('No assessment response ID provided');
          throw new Error('No assessment response ID provided');
        }

        console.log('Regular assessment review initialization - ID:', assessmentResponseId);
        console.log('Score:', score, 'Possible Score:', possibleScore);

        try {
          console.log('Attempting API request to:', `/api/assessment_response/${assessmentResponseId}`);
          const response = await api.get(`/api/assessment_response/${assessmentResponseId}`, {
            headers: { Authorization: `Bearer ${access_token}` }
          });
          
          console.log('API response successful:', response.status);
          const apiData = response.data;
          
          // Create the review data object
          const reviewData = {
            questions: apiData.questions || [],
            score: score,
            possible_score: possibleScore
          };
          
          // Calculate correct answers
          if (apiData.questions && Array.isArray(apiData.questions)) {
            const correctAnswers = apiData.questions.filter(q => 
              q.userAnswer === q.correctAnswer
            ).length;
            setCorrectCount(correctAnswers);
          }
          
          setAssessmentReview(reviewData);
        } catch (apiError) {
          console.error('API request failed:', apiError);
          
          // Even if API fails, we can still show basic score info
          setAssessmentReview({
            questions: [],
            score: score,
            possible_score: possibleScore
          });
        }
      } catch (error) {
        console.error('Error in fetchReviewData:', error);
        setError(error.message || 'Failed to load assessment review');
      } finally {
        console.log('Setting loading to false');
        setLoading(false);
      }
    };

    fetchReviewData();
  }, [assessmentResponseId, score, possibleScore, access_token]);

  // Add a loading timeout
  useEffect(() => {
    let timeoutId;
    
    if (loading) {
      timeoutId = setTimeout(() => {
        console.log('Loading timeout - showing minimal view');
        setLoading(false);
        // Even if loading times out, show basic score info
        if (!assessmentreview) {
          setAssessmentReview({
            questions: [],
            score: score,
            possible_score: possibleScore
          });
        }
      }, 10000); // 10 second timeout
    }
    
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [loading, score, possibleScore, assessmentreview]);

  // Calculate the percentage score
  const calculatePercentage = () => {
    if (!assessmentreview) return 0;
    
    if (assessmentreview.score !== undefined && assessmentreview.possible_score) {
      return Math.round((assessmentreview.score / assessmentreview.possible_score) * 100);
    }
    
    // Fallback to questions-based calculation
    if (assessmentreview.questions && assessmentreview.questions.length > 0) {
      return Math.round((correctCount / assessmentreview.questions.length) * 100);
    }
    
    return 0;
  };

  // Get score color based on percentage
  const getScoreColor = (percent) => {
    if (percent >= 80) return '#4CAF50';
    if (percent >= 60) return '#FFC107';
    return '#F44336';
  };

  // Handler for printing the results
  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <div className="assessment-review-container loading-container">
        <div className="loading-spinner"></div>
        <p>Loading assessment results...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="assessment-review-container error-container">
        <h2>Error</h2>
        <p>{error}</p>
        <Link to="/AdaptiveAssessments" className="return-button">Return to Assessments</Link>
      </div>
    );
  }

  // If no data at all, handle it
  if (!assessmentreview) {
    return (
      <div className="assessment-review-container error-container">
        <h2>No Data Available</h2>
        <p>Could not find any assessment results to display.</p>
        <Link to="/AdaptiveAssessments" className="return-button">Return to Assessments</Link>
      </div>
    );
  }

  const percentage = calculatePercentage();
  const scoreColor = getScoreColor(percentage);
  const showMinimalView = !assessmentreview.questions || assessmentreview.questions.length === 0;
  
  return (
    <div className="assessment-review-container">
      <h2 className="review-header">Assessment Review</h2>

      {/* Summary Section */}
      <div className="assessment-summary">
        <div className="score-chart">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              textSize: '22px',
              pathColor: scoreColor,
              textColor: scoreColor,
              trailColor: '#e6e6e6',
            })}
          />
        </div>
        
        <div className="score-details">
          <h3 className="score-title">Your Score</h3>
          <p className="score-text">
            You scored <strong>{assessmentreview.score || 0}</strong> out of <strong>{assessmentreview.possible_score || 0}</strong> points.
          </p>
        </div>
        
        {/* Show a message if we're only showing minimal data */}
        {showMinimalView && (
          <div className="minimal-view-notice">
            <p>Detailed question review is not available, but your score has been recorded.</p>
          </div>
        )}
      </div>

      {/* Questions Review Section - only show if we have questions */}
      {!showMinimalView && (
        <div className="questions-review">
          <h3 className="questions-title">Questions Review</h3>
          <ul className="review-list">
            {assessmentreview.questions.map((question, index) => {
              // Get question properties, handling different property names
              const questionText = question.text || question.question || '';
              const questionImage = question.image || '';
              const questionType = question.type || '';
              const userAnswer = question.userAnswer || question.answer || '';
              const correctAnswer = question.correctAnswer || question.correct_answer || '';
              const isCorrect = userAnswer === correctAnswer || 
                                (Array.isArray(userAnswer) && 
                                 Array.isArray(correctAnswer) && 
                                 JSON.stringify(userAnswer.sort()) === JSON.stringify(correctAnswer.sort()));
              const choices = question.choices || [];
              
              // Format answers based on question type
              const formatAnswer = (answer, isUserAnswer = false) => {
                if (!answer) return 'No answer provided';
                
                // For multiple choice questions
                if (questionType === 'MCQ' || questionType === 'MCQA') {
                  // If we have choices, try to match IDs to text
                  if (choices && choices.length > 0) {
                    if (Array.isArray(answer)) {
                      return answer.map(ans => {
                        const choice = choices.find(c => c.id === ans || c.text === ans);
                        return choice ? choice.text : ans;
                      }).join(', ');
                    } else {
                      const choice = choices.find(c => c.id === answer || c.text === answer);
                      return choice ? choice.text : answer;
                    }
                  }
                }
                
                // For short answer questions or if we couldn't format as multiple choice
                return answer.toString();
              };
              
              return (
                <li key={question.id || index} className={`review-item ${isCorrect ? 'correct' : 'incorrect'}`}>
                  <div className="question-header">
                    <span className="question-number">Q{index + 1}:</span>
                    <span className={`status-indicator ${isCorrect ? 'correct' : 'incorrect'}`}>
                      {isCorrect ? 'Correct' : 'Incorrect'}
                    </span>
                    {questionType && (
                      <span className="question-type">
                        {questionType === 'SA' && 'Short Answer'}
                        {questionType === 'MCQ' && 'Multiple Choice'}
                        {questionType === 'MCQA' && 'Multiple Select'}
                        {!['SA', 'MCQ', 'MCQA'].includes(questionType) && questionType}
                      </span>
                    )}
                  </div>
                  
                  <div className="question-content">
                    {questionImage && (
                      <div className="question-image">
                        <img src={questionImage} alt={`Question ${index + 1}`} />
                      </div>
                    )}
                    <p className="question-text">{questionText}</p>
                    
                    {/* Show choices for multiple choice questions */}
                    {(questionType === 'MCQ' || questionType === 'MCQA') && choices && choices.length > 0 && (
                      <div className="question-choices">
                        <p className="choices-label">Answer Choices:</p>
                        <ul className="choices-list">
                          {choices.map((choice, choiceIndex) => {
                            const isUserSelected = Array.isArray(userAnswer) 
                              ? userAnswer.includes(choice.id) 
                              : userAnswer === choice.id;
                            const isCorrectChoice = Array.isArray(correctAnswer)
                              ? correctAnswer.includes(choice.id)
                              : correctAnswer === choice.id;
                            
                            return (
                              <li 
                                key={choice.id || choiceIndex} 
                                className={`choice-item ${isUserSelected ? 'user-selected' : ''} ${isCorrectChoice ? 'correct-choice' : ''}`}
                              >
                                <span className="choice-marker">
                                  {isUserSelected && '✓ '}
                                  {isCorrectChoice && !isUserSelected && '⭐ '}
                                </span>
                                {choice.text}
                                {choice.image && (
                                  <img 
                                    className="choice-image" 
                                    src={choice.image} 
                                    alt={`Choice ${choiceIndex + 1}`} 
                                  />
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                  
                  <div className="answer-details">
                    <div className="user-answer">
                      <strong>Your Answer:</strong> {formatAnswer(userAnswer, true)}
                    </div>
                    {!isCorrect && (
                      <div className="correct-answer">
                        <strong>Correct Answer:</strong> {formatAnswer(correctAnswer)}
                      </div>
                    )}
                    {question.explanation && (
                      <div className="explanation">
                        <strong>Explanation:</strong> {question.explanation}
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {/* Action Buttons */}
      <div className="action-buttons">
        <Link to="/AdaptiveAssessments" className="return-button">Return to Assessments</Link>
        <button onClick={handlePrint} className="print-button">Print Results</button>
      </div>
    </div>
  );
};

export default RegularAssessmentReview; 