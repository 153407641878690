import { useState, useEffect, useCallback } from 'react';

const useSound = (url) => {
    const [audio] = useState(() => {
        const sound = new Audio(url);
        sound.loop = false; // Default is no looping
        return sound;
    });

    const play = useCallback(() => {
        try {
            // Clone the audio element to avoid issues with concurrent plays
            const soundClone = new Audio(url);
            soundClone.volume = 0.3; // Reduce volume to prevent jarring sounds
            soundClone.play().catch((error) => {
                // Silently fail on errors to prevent UI freezing
                console.error("Audio playback failed:", error);
            });
        } catch (error) {
            // Catch any errors to prevent UI freezing
            console.error("Audio playback failed:", error);
        }
    }, [url]);

    const stop = useCallback(() => {
        try {
            audio.pause();
            audio.currentTime = 0; // Reset to the start
        } catch (error) {
            console.error("Failed to stop audio:", error);
        }
    }, [audio]);

    const setLoop = useCallback((shouldLoop) => {
        try {
            audio.loop = shouldLoop;
        } catch (error) {
            console.error("Failed to set loop:", error);
        }
    }, [audio]);

    useEffect(() => {
        // Cleanup function to stop audio on component unmount
        return () => {
            try {
                audio.pause();
                audio.currentTime = 0;
            } catch (error) {
                console.error("Failed to cleanup audio:", error);
            }
        };
    }, [audio]);

    return { play, stop, setLoop };
};

export default useSound;
