import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #444;
  cursor: pointer;
  background: #f0f0f3;
  box-shadow: 5px 5px 10px #d1d1d4,
              -5px -5px 10px #ffffff;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 3px 3px 6px #d1d1d4,
                -3px -3px 6px #ffffff;
    transform: translateY(-2px);
  }
  
  &:active {
    box-shadow: inset 3px 3px 5px #d1d1d4,
                inset -3px -3px 5px #ffffff;
    transform: translateY(0);
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #444;
  text-decoration: none;
  cursor: pointer;
  background: #f0f0f3;
  box-shadow: 5px 5px 10px #d1d1d4,
              -5px -5px 10px #ffffff;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 3px 3px 6px #d1d1d4,
                -3px -3px 6px #ffffff;
    transform: translateY(-2px);
  }
  
  &:active {
    box-shadow: inset 3px 3px 5px #d1d1d4,
                inset -3px -3px 5px #ffffff;
    transform: translateY(0);
  }
`;

export const NeumorphicButton = ({ 
  children, 
  onClick, 
  isLink = false, 
  to = "/", 
  ...props 
}) => {
  if (isLink) {
    return (
      <StyledLink to={to} {...props}>
        {children}
      </StyledLink>
    );
  }
  
  return (
    <Button onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export default NeumorphicButton; 