
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { PMREMGenerator } from 'three';
import './AnatomyThreeScene.css';
import { useSelector } from 'react-redux';
import AnatomyInviteLink from './AnatomyInviteLink';
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';

const AnatomyThreeScene = ({ RoomNumber = 5, userId = 0 }) => {
const { roomNumber: paramRoomNumber, userId: paramUserId } = useParams(); // Get route params
const mountRef = useRef(null);
const [hoveredPart, setHoveredPart] = useState('');
const [currentModel, setCurrentModel] = useState('CNS2.glb');
const [scale, setScale] = useState(0.05);
const [loading, setLoading] = useState(false);
const [socket, setSocket] = useState(null);
const [host, setHost] = useState(false)
const controlsRef = useRef(null);
const currentSceneRef = useRef(null); // Reference to store the current model scene
const [inVRMode, setInVRMode] = useState(false); // Track VR mode state
const controllerData = useRef({
leftGrip: false,
rightGrip: false,
initialDistance: null,
initialScale: 1,
lastScaleTime: 0
});

// Get userInfo from Redux
const userInfo = useSelector((state) => state.userLogin.userInfo); 

// Determine roomNumber and userId based on params or fallback to Redux/state logic
const roomNumber = paramRoomNumber || RoomNumber; // Use params or a default

function generateRandomNumber() {
return Math.floor(Math.random() * 1000000); // Generates a random number between 0 and 999,999
}

// Calculate VR-specific scale based on model
const getVRModelScale = (modelName) => {
// Define VR scales based on model type
const vrScaleMap = {
'CNS2.glb': 0.7,
'muscles.glb': 2,
'nervous_system4.glb': 1,
'Skeleton.glb': 1.5,
'Circulatory_system.glb': 4,
'Circulatory_system2.glb': 4,
'gastrointestinal_tract.glb': 4,
'glb20250301/kidney.glb': 0.25,
// Add more models with their appropriate VR scale values
};

// Return the VR scale for the model, or a default value if not found
return vrScaleMap[modelName] || 10;
};

// Get VR-specific model position based on model type
const getVRModelPosition = (modelName) => {
// Define model-specific positions - format: [x, y, z]
// y: vertical position (0 = floor level, 1.6 = eye level)
// z: distance from user (-2 = 2 meters away)
const positionMap = {
// Full body models should have "feet" touching the ground
'muscles.glb': [0, 0, -2], // Place on ground
'Skeleton.glb': [0, 0, -2], // Place on ground
'nervous_system4.glb': [0, 0, -2], // Place on ground
'Circulatory_system.glb': [0, 0, -2], // Place on ground
'Circulatory_system2.glb': [0, 0, -2], // Place on ground

// Smaller/partial models should be at eye level
'CNS2.glb': [0, 1.3, -1.5], // Eye level, closer
'gastrointestinal_tract.glb': [0, 1.2, -1.5], // Slightly below eye level
'glb20250301/kidney.glb': [0, 1.3, -1], // Eye level, even closer
};

// Return position or default to eye level if not specified
return positionMap[modelName] || [0, 1.3, -1.5];
};

useEffect(() => {
let currentScene = null; // To hold the current loaded scene
let frameId; // To track the animation frame for cleanup

// Set up the main Three.js scene
const scene = new THREE.Scene(); // Create a new scene
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 2000);
camera.position.set(0, 0.11, 0.1); // Adjust camera for better initial view
camera.lookAt(new THREE.Vector3(0, 1.1, 0.1)); // Ensure it's looking forward

scene.background = new THREE.Color("#282c34"); // Background color for the scene

const renderer = new THREE.WebGLRenderer({ antialias: true }); // Create the renderer
renderer.setSize(window.innerWidth, window.innerHeight); // Match renderer size to viewport
renderer.toneMapping = THREE.ACESFilmicToneMapping; // Enable tone mapping
renderer.toneMappingExposure = 0.5; // Control the brightness
renderer.shadowMap.enabled = true; // Enable shadows
renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Set shadow type for softness
renderer.xr.enabled = true;
mountRef.current.innerHTML = ''; // Clear previous scene from the DOM
mountRef.current.appendChild(renderer.domElement); // Attach the renderer canvas to the DOM

// Set up VR
document.body.appendChild(VRButton.createButton(renderer));

// Set up controllers for VR
const controllers = [];
const controllerGrips = [];
const controllerModelFactory = new XRControllerModelFactory();

// Add controller 0 (right hand)
const controller0 = renderer.xr.getController(0);
controller0.addEventListener('selectstart', onSelectStart);
controller0.addEventListener('selectend', onSelectEnd);
controller0.addEventListener('squeezestart', () => onGripStart('right'));
controller0.addEventListener('squeezeend', () => onGripEnd('right'));
controller0.name = 'right';
scene.add(controller0);
controllers.push(controller0);

// Add controller 1 (left hand)
const controller1 = renderer.xr.getController(1);
controller1.addEventListener('selectstart', onSelectStart);
controller1.addEventListener('selectend', onSelectEnd);
controller1.addEventListener('squeezestart', () => onGripStart('left'));
controller1.addEventListener('squeezeend', () => onGripEnd('left'));
controller1.name = 'left';
scene.add(controller1);
controllers.push(controller1);

// Add controller models (visual representation of controllers)
const controllerGrip0 = renderer.xr.getControllerGrip(0);
controllerGrip0.add(controllerModelFactory.createControllerModel(controllerGrip0));
scene.add(controllerGrip0);
controllerGrips.push(controllerGrip0);

const controllerGrip1 = renderer.xr.getControllerGrip(1);
controllerGrip1.add(controllerModelFactory.createControllerModel(controllerGrip1));
scene.add(controllerGrip1);
controllerGrips.push(controllerGrip1);

// Add controller geometries (rays)
controllers.forEach((controller) => {
const controllerGeometry = new THREE.BufferGeometry().setFromPoints([
new THREE.Vector3(0, 0, 0),
new THREE.Vector3(0, 0, -1)
]);

const controllerLine = new THREE.Line(
controllerGeometry,
new THREE.LineBasicMaterial({ color: 0xff0000 })
);
controllerLine.scale.z = 5;
controller.add(controllerLine);
});

// Controller event handlers
function onSelectStart(event) {
const controller = event.target;
console.log(`🎮 Select start with ${controller.name} controller`);

// Highlight the controller ray when selected
controller.children[0].material.color.set(0x00ff00);
}

function onSelectEnd(event) {
const controller = event.target;
console.log(`🎮 Select end with ${controller.name} controller`);

// Return controller ray to original color
controller.children[0].material.color.set(0xff0000);
}

function onGripStart(hand) {
console.log(`🎮 Grip start with ${hand} controller`);
if (hand === 'left') {
controllerData.current.leftGrip = true;
} else {
controllerData.current.rightGrip = true;
}

// If both grips are active, start scaling mode
if (controllerData.current.leftGrip && controllerData.current.rightGrip) {
// Get current distance between controllers for scaling reference
const leftPos = new THREE.Vector3();
const rightPos = new THREE.Vector3();
controllerGrip0.getWorldPosition(rightPos);
controllerGrip1.getWorldPosition(leftPos);

controllerData.current.initialDistance = leftPos.distanceTo(rightPos);

if (currentSceneRef.current) {
controllerData.current.initialScale = currentSceneRef.current.scale.x;
}

console.log(`🔄 Starting scaling. Initial distance: ${controllerData.current.initialDistance}`);
}
}

function onGripEnd(hand) {
console.log(`🎮 Grip end with ${hand} controller`);
if (hand === 'left') {
controllerData.current.leftGrip = false;
} else {
controllerData.current.rightGrip = false;
}

// Reset scaling mode data when grips are released
if (!controllerData.current.leftGrip || !controllerData.current.rightGrip) {
controllerData.current.initialDistance = null;
}
}

// Function to handle model manipulation in VR
function updateVRInteractions() {
if (!inVRMode || !currentSceneRef.current) return;

// Scaling with both grips
if (controllerData.current.leftGrip && controllerData.current.rightGrip && 
controllerData.current.initialDistance !== null) {

const leftPos = new THREE.Vector3();
const rightPos = new THREE.Vector3();
controllerGrip0.getWorldPosition(rightPos);
controllerGrip1.getWorldPosition(leftPos);

const currentDistance = leftPos.distanceTo(rightPos);
const scaleFactor = currentDistance / controllerData.current.initialDistance;

// Apply scaling with a threshold to avoid jitter
// Only update every 100ms to avoid too frequent updates
const now = Date.now();
if (now - controllerData.current.lastScaleTime > 100) {
const newScale = controllerData.current.initialScale * scaleFactor;

// Limit scaling between 0.5x and 3x of the VR model scale
const minScale = getVRModelScale(currentModel) * 0.5;
const maxScale = getVRModelScale(currentModel) * 3;

const clampedScale = Math.max(minScale, Math.min(maxScale, newScale));
currentSceneRef.current.scale.set(clampedScale, clampedScale, clampedScale);

console.log(`🔄 Scaling model. Factor: ${scaleFactor.toFixed(2)}, New scale: ${clampedScale.toFixed(2)}`);
controllerData.current.lastScaleTime = now;
}
}

// Model rotation with one controller
if (controllerData.current.rightGrip && !controllerData.current.leftGrip) {
const controller = controllerGrip0;

// Rotate model based on controller movement
// This provides a simple orbit-like rotation
const rotationSpeed = 0.02;
currentSceneRef.current.rotation.y += rotationSpeed;
}

// Model elevation with left controller
if (controllerData.current.leftGrip && !controllerData.current.rightGrip) {
const controller = controllerGrip1;

// Move model up/down based on controller position
const moveSpeed = 0.01;
currentSceneRef.current.rotation.x += moveSpeed;
}
}

const controls = new OrbitControls(camera, renderer.domElement); // Add camera controls
controls.enableDamping = true; // Enable damping for smooth camera motion
controls.dampingFactor = 0.05; // Adjust damping factor
controlsRef.current = controls; // Store controls in a ref for later use

// Add a listener for camera changes to sync via WebSocket
controls.addEventListener('change', () => {
if (socket) {
const cameraState = {
position: camera.position.toArray(),
rotation: camera.rotation.toArray(),
};
socket.send(JSON.stringify({ type: 'camera_update', cameraState })); // Broadcast camera state
}
});

// Set up the room environment for realistic lighting
const pmremGenerator = new PMREMGenerator(renderer); // Generate pre-filtered radiance map
const environment = new RoomEnvironment(pmremGenerator); // Room-like environment
scene.environment = pmremGenerator.fromScene(environment).texture; // Set as the scene environment

// Helper function to add lighting based on object size and position
const addLightsBasedOnObject = (scene, object) => {
const box = new THREE.Box3().setFromObject(object); // Calculate bounding box of the object
const objectCenter = box.getCenter(new THREE.Vector3()); // Get the center of the bounding box
const objectSize = box.getSize(new THREE.Vector3()); // Get the size of the bounding box
const lightIntensity = 3; // Intensity of the directional lights
const lightDistance = objectSize.length() * 1.5; // Adjust light distance based on object size

const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Add ambient light
scene.add(ambientLight);

// Add directional lights from multiple angles
const directionalLight1 = new THREE.DirectionalLight(0xffffff, lightIntensity);
directionalLight1.position.set(objectCenter.x + lightDistance, objectCenter.y + lightDistance, objectCenter.z);
directionalLight1.castShadow = true; // Enable shadows
directionalLight1.shadow.mapSize.width = 2048; // Shadow map resolution
directionalLight1.shadow.mapSize.height = 2048;
scene.add(directionalLight1);

const directionalLight2 = new THREE.DirectionalLight(0xffffff, lightIntensity);
directionalLight2.position.set(objectCenter.x - lightDistance, objectCenter.y + lightDistance, objectCenter.z);
directionalLight2.castShadow = true;
scene.add(directionalLight2);

const directionalLight3 = new THREE.DirectionalLight(0xffffff, lightIntensity);
directionalLight3.position.set(objectCenter.x, objectCenter.y + lightDistance, objectCenter.z + lightDistance);
directionalLight3.castShadow = true;
scene.add(directionalLight3);
};

// Set up the GLTF model loader
const loader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('three/examples/jsm/libs/draco/gltf'); // Path to DRACO decoder
loader.setDRACOLoader(dracoLoader); // Attach DRACO to GLTF loader

// making new loader that can handle gltf files 
const loadModel2 = (modelPath) => {
setLoading(true); // Show loading spinner
const gltfLoader = new GLTFLoader();
gltfLoader.load(modelPath, (gltfScene) => {
currentScene = gltfScene.scene;
currentScene.position.set(0, 0, 0);
currentScene.scale.set(scale, scale, scale);
scene.add(currentScene);
setLoading(false);
});
}

// making new loader that can handle gltf files 
const loadModel = (modelPath) => {
if (currentScene) {
scene.remove(currentScene); // Remove previous model
}

setLoading(true); // Show loading spinner
const fileExtension = modelPath.split('.').pop().toLowerCase();

if (fileExtension === "glb" || fileExtension === "gltf") {
const loader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('three/examples/jsm/libs/draco/gltf/'); // DRACO decoder path
loader.setDRACOLoader(dracoLoader);

loader.load(
modelPath,
(gltf) => {
gltf.scene.traverse((node) => {
if (node.isMesh) {
node.castShadow = true;
node.receiveShadow = true;
}
});

currentScene = gltf.scene;
currentSceneRef.current = currentScene; // Store reference to current model

// Position and scale based on whether in VR mode or not
if (inVRMode) {
const vrScale = getVRModelScale(modelPath);
const [posX, posY, posZ] = getVRModelPosition(modelPath);
currentScene.position.set(posX, posY, posZ);
currentScene.scale.set(vrScale, vrScale, vrScale);
} else {
currentScene.position.set(0, 0, 0);
currentScene.scale.set(scale, scale, scale);
}

scene.add(currentScene);
setLoading(false);
},
undefined,
(error) => {
console.error(`Error loading GLB/GLTF model: ${modelPath}`, error);
setLoading(false);
}
);
} else if (fileExtension === "fbx") {
const fbxLoader = new FBXLoader();
fbxLoader.load(
modelPath,
(fbx) => {
fbx.traverse((node) => {
if (node.isMesh) {
node.castShadow = true;
node.receiveShadow = true;
}
});

currentScene = fbx;
currentSceneRef.current = currentScene; // Store reference to current model

// Position and scale based on whether in VR mode or not
if (inVRMode) {
const vrScale = getVRModelScale(modelPath);
const [posX, posY, posZ] = getVRModelPosition(modelPath);
currentScene.position.set(posX, posY, posZ);
currentScene.scale.set(vrScale, vrScale, vrScale);
} else {
currentScene.position.set(0, 0, 0);
currentScene.scale.set(scale, scale, scale);
}

scene.add(currentScene);
setLoading(false);
},
undefined,
(error) => {
console.error(`Error loading FBX model: ${modelPath}`, error);
setLoading(false);
}
);
} else {
console.error("Unsupported model format:", modelPath);
setLoading(false);
}
};

loadModel(currentModel); // Load the initial model

// Raycaster for detecting hovered parts
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

// Handle mouse movement for hover detection
const handleMouseMove = (event) => {
mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
raycaster.setFromCamera(mouse, camera);

const intersects = raycaster.intersectObjects(scene.children, true);
if (intersects.length > 0) {
setHoveredPart(intersects[0].object.name || 'Unknown part');
} else {
setHoveredPart('');
}
};

// Handle window resize for responsive rendering
const handleResize = () => {
camera.aspect = window.innerWidth / window.innerHeight;
camera.updateProjectionMatrix();
renderer.setSize(window.innerWidth, window.innerHeight);
};

window.addEventListener('mousemove', handleMouseMove); // Add mouse movement listener
window.addEventListener('resize', handleResize); // Add window resize listener

// Axes helper for scene reference
const axesScene = new THREE.Scene();
const axesHelper = new THREE.AxesHelper(0.1);
{/* scene.add(axesHelper);*/} 

const axesCamera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, 0.01, 10);
axesCamera.position.set(1, 1, 1);
axesCamera.lookAt(0, 0, 0);

const axesRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
axesRenderer.setSize(100, 100);
axesRenderer.domElement.style.position = 'absolute';
axesRenderer.domElement.style.top = '10px';
axesRenderer.domElement.style.left = '10px';
axesRenderer.setClearColor(0x000000, 0);

const animate = () => {
controls.update();

if (renderer.xr.isPresenting) {
updateVRInteractions();
}

renderer.render(scene, camera);
axesRenderer.render(axesScene, axesCamera);
};

// Use proper animation loop method with renderer.setAnimationLoop
renderer.setAnimationLoop(animate);

// VR Session start event handler
renderer.xr.addEventListener("sessionstart", () => {
setInVRMode(true); // Set VR mode state to true
console.log("🔹 VR session started");

// Add VR instruction text
const instructionTexture = new THREE.CanvasTexture(createInstructionCanvas());
const instructionMaterial = new THREE.MeshBasicMaterial({ 
map: instructionTexture,
transparent: true,
opacity: 0.8
});
const instructionGeometry = new THREE.PlaneGeometry(1, 0.5);
const instructionMesh = new THREE.Mesh(instructionGeometry, instructionMaterial);
instructionMesh.position.set(10, 2.2, -2);
instructionMesh.name = "vrInstructions";
scene.add(instructionMesh);

// Function to create canvas with instructions
function createInstructionCanvas() {
const canvas = document.createElement('canvas');
canvas.width = 512;
canvas.height = 256;
const context = canvas.getContext('2d');

// Fill background
context.fillStyle = 'rgba(0, 0, 0, 0.7)';
context.fillRect(0, 0, canvas.width, canvas.height);

// Add border
context.strokeStyle = 'white';
context.lineWidth = 4;
context.strokeRect(10, 10, canvas.width - 20, canvas.height - 20);

// Add text
context.fillStyle = 'white';
context.font = '24px Arial';
context.textAlign = 'center';
context.fillText('VR CONTROLS', canvas.width/2, 40);

context.font = '18px Arial';
context.textAlign = 'left';
context.fillText('• Grip both controllers: Scale model (pinch)', 30, 80);
context.fillText('• Grip right controller: Rotate model Y axis', 30, 120);
context.fillText('• Grip left controller: Rotate model X axis', 30, 160);
context.fillText('• Trigger buttons: Interact (future use)', 30, 200);

return canvas;
}

// Get VR camera
const xrCamera = renderer.xr.getCamera(camera);

// Position the VR camera at human eye level (about 1.6m)
xrCamera.position.set(0, 1.6, 0); 

// Make the camera look forward
xrCamera.lookAt(new THREE.Vector3(0, 1.6, -3)); 

// Apply VR-specific positioning and scaling to the current model
if (currentSceneRef.current) {
const vrScale = getVRModelScale(currentModel);
const [posX, posY, posZ] = getVRModelPosition(currentModel);

// Position the model based on its type
currentSceneRef.current.position.set(posX, posY, posZ); 

// Scale the model to human-like proportions in VR
currentSceneRef.current.scale.set(vrScale, vrScale, vrScale);

console.log(`🔹 Model adjusted for VR: ${currentModel} with scale ${vrScale} at position [${posX}, ${posY}, ${posZ}]`);
}
});

// VR Session end event handler
renderer.xr.addEventListener("sessionend", () => {
setInVRMode(false);
console.log("🔹 VR session ended");

// Remove VR instruction text if it exists
const instructions = scene.getObjectByName("vrInstructions");
if (instructions) {
scene.remove(instructions);
}

// Reset the model position and scale for normal view
if (currentSceneRef.current) {
currentSceneRef.current.position.set(0, 0, 0);
currentSceneRef.current.scale.set(scale, scale, scale);
currentSceneRef.current.rotation.set(0, 0, 0); // Reset rotation
}
});

// Cleanup when the component unmounts
return () => {
window.removeEventListener('mousemove', handleMouseMove);
window.removeEventListener('resize', handleResize);
cancelAnimationFrame(frameId); // Stop animation loop
mountRef.current.removeChild(renderer.domElement); // Remove renderer DOM element
};
}, [currentModel, scale, socket, inVRMode]); // Added inVRMode to dependencies

useEffect(() => {
if (socket && controlsRef.current) {
const controls = controlsRef.current;

// Update and send camera position/rotation on control change
controls.addEventListener('change', () => {
const cameraPosition = {
x: controls.object.position.x,
y: controls.object.position.y,
z: controls.object.position.z,
};

const cameraRotation = {
x: controls.object.rotation.x,
y: controls.object.rotation.y,
z: controls.object.rotation.z,
};

socket.send(
JSON.stringify({
type: 'camera_update',
position: cameraPosition,
rotation: cameraRotation,
})
);
});
}
}, [socket]);

useEffect(() => {
if (host) {
console.log("host");
}},
[host]);

useEffect(() => {
const ws = new WebSocket(`wss://api.andrewslearning.com/wschat/screen-sharing/${roomNumber}/${userId}/`);
setSocket(ws);

ws.onopen = () => {
console.log("WebSocket connected:", { roomNumber, userId });

// Identify as host and send the initial message
ws.send(
JSON.stringify({
type: "identify_host",
profile_number: userId || generateRandomNumber(), // Use user ID or generate random number
sender: userInfo.username || "none", // Use username or fallback to "none"
})
);
};

ws.onmessage = (event) => {
const data = JSON.parse(event.data);

if (data.type === 'host_acknowledged' && data.host_id === userInfo.id) {
setHost(true);
console.log("Host acknowledged:", data.host_id);
}

if (data.type === 'camera_update') {
const { position, rotation } = data.cameraState;
if (controlsRef.current) {
const camera = controlsRef.current.object;

// Correct for swapped axes in position
camera.position.set(position[0], position[1], position[2]);

// Correct for swapped axes in rotation
camera.rotation.set(rotation[2], rotation[1], rotation[0]);

controlsRef.current.update(); // Sync controls
console.log('Camera updated:', { position, rotation });
}
} else if (data.type === 'model_selected') {
setCurrentModel(data.model);
} else if (data.type === 'scale_change') {
setScale(data.scale);
}
};

ws.onclose = () => {
console.log('WebSocket disconnected');
};

ws.onerror = (error) => {
console.error('WebSocket error:', error);
};

return () => {
ws.close();
};
}, [roomNumber, userInfo.id]);

useEffect(() => {
if (socket && host && controlsRef.current) {
const controls = controlsRef.current;

// Add event listener to send camera updates only if the client is the host
const handleControlsChange = () => {
const cameraState = {
position: controls.object.position.toArray(),
rotation: controls.object.rotation.toArray(),
};
socket.send(
JSON.stringify({
type: 'camera_update',
cameraState,
})
);
};

controls.addEventListener('change', handleControlsChange);

// Cleanup listener on unmount or when host changes
return () => {
controls.removeEventListener('change', handleControlsChange);
};
}
}, [socket, host]);


const handleModelChange = (model) => {
setCurrentModel(model);
if (socket && host) {
socket.send(JSON.stringify({ type: 'model_selected', model }));
}
};

const handleScaleChange = (newScale) => {
setScale(newScale);
if (socket && host) {
socket.send(JSON.stringify({ type: 'scale_change', scale: newScale }));
}
};


return (
<div className="anatomy-app">
<header className="app-header">
<center>
<h1>Anatomy Explorer</h1>
</center>

</header>
{host && <p>Host: {host}</p>}
<p className="app-description">
Welcome to the Anatomy Explorer! This interactive tool allows you to explore the human body's different systems in 3D. Use your mouse to interact with the model:
<ul>
<li><strong>Zoom:</strong> Scroll your mouse wheel up or down to zoom in and out.</li>
<li><strong>Rotate:</strong> Click and drag with the left mouse button to rotate the model and view it from different angles.</li>
<li><strong>Pan:</strong> Hold the right mouse button or Ctrl key while clicking and dragging to move the model around the screen.</li>
<li><strong>Reset View:</strong> Use the controls in the corner or double-click the canvas to reset the view to its original position.</li>
</ul>
Click on the buttons above to load and explore different anatomical systems like the muscular, nervous, circulatory, and gastrointestinal systems. Adjust the model's scale with the slider and hover over parts of the model to learn more about each structure.
</p>

<div className="app-controls">
<button className="app-button" onClick={() => {
setCurrentModel('muscles.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'muscles.glb' }));
}
}}>
Muscular System
</button>
<button className="app-button" onClick={() => {
setCurrentModel('nervous_system4.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'nervous_system4.glb' }));
}
}}>
Nervous System
</button>
<button className="app-button" onClick={() => {
setCurrentModel('Skeleton.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'Skeleton.glb' }));
}
}}>
Skeletal System
</button>
<button className="app-button" onClick={() => {
setCurrentModel('Circulatory_system.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'Circulatory_system.glb' }));
}
}}>
Female Circulatory System
</button>
{/* <button className="app-button" onClick={() => {
setCurrentModel('glb20250301/Male_Full_Body_Anatomy.fbx'); // Set FBX model path
console.log("test")
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'glb20250301/Male_Full_Body_Anatomy.fbx' }));
}
}}>
Male Full Body Anatomy
</button>
*/}
<button className="app-button" onClick={() => {
setCurrentModel('Circulatory_system2.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'Circulatory_system2.glb' }));
}
}}>


Male Circulatory System
</button>
<button className="app-button" onClick={() => {
setCurrentModel('Male_Full_Body_AnatomyV2.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'Male_Full_Body_AnatomyV2.glb' }));
}
}}>
Test_button
</button>
<button className="app-button" onClick={() => {
setCurrentModel('gastrointestinal_tract.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'gastrointestinal_tract.glb' }));
}
}}>
Gastrointestinal System
</button>
{/* <button className="app-button" onClick={() => {
setCurrentModel('glb20250301/hh.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'glb20250301/hh.glb' }));
}
}}>
Heart
</button>


<button className="app-button" onClick={() => {
setCurrentModel('glb20250301/male_reproductive_system.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'glb20250301/male_reproductive_system.glb' }));
}
}}> 
Male Reproductive System
</button>*/}
<button className="app-button" onClick={() => {
setCurrentModel('glb20250301/kidney.glb');
if (socket) {
socket.send(JSON.stringify({ type: 'model_selected', model: 'glb20250301/kidney.glb' }));
}
}}>
Kidney
</button>

</div>

<div className="app-slider">
<label htmlFor="scale-slider">Model Scale: {scale.toFixed(2)}</label>
<input id="scale-slider" type="range" min="0.1" max="2" step="0.1" value={scale} onChange={(e) => {
const newScale = parseFloat(e.target.value);
setScale(newScale);
if (socket) {
socket.send(JSON.stringify({ type: 'scale_change', scale: newScale }));
}
}} />
</div>

<div className="app-canvas" ref={mountRef} style={{

border: "2px solid #ccc",
borderRadius: "10px",
boxShadow: "0px 15px 20px rgba(0,0,0,0.2)",
backgroundColor: "#282c34",
maxHeight:"auto",

}}
></div>
{loading && (
<div className="loading-container">
<div className="loading-spinner"></div>
<p>Loading model, please wait...</p>
</div>
)}

{hoveredPart && (
<div className="tooltip">
{hoveredPart}
</div>
)}

</div>
);
};

export default AnatomyThreeScene; 
