import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useLocation } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './assessmentreview.css';
import { useSelector } from 'react-redux';
import api from './api';

const AssessmentResponsePage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assessmentreview, setAssessmentReview] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const { assessmentResponseId } = useParams(); // Get the ID from URL params
  const location = useLocation(); // Get state passed from assessment completion
  
  // Get data from URL query parameters or location.state
  const getStateParam = (name, defaultValue) => {
    // First check URL query parameters
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has(name)) {
      const value = queryParams.get(name);
      // Convert string "true"/"false" to boolean if needed
      if (value === 'true') return true;
      if (value === 'false') return false;
      // Try to parse as number if it looks like one
      if (!isNaN(value) && value !== '') return Number(value);
      // Special case for timePerQuestion which might be a JSON string
      if (name === 'timePerQuestion' && value.startsWith('[')) {
        try {
          return JSON.parse(value);
        } catch (e) {
          console.error('Failed to parse timePerQuestion:', e);
          return defaultValue;
        }
      }
      return value;
    }
    // Then check location.state
    if (location.state && location.state[name] !== undefined) {
      return location.state[name];
    }
    // Finally return default
    return defaultValue;
  };
  
  // Set up state from URL or location, with fallbacks
  const isCatBased = getStateParam('isCatBased', false);
  const abilityScore = getStateParam('abilityScore', 0);
  const score = getStateParam('score', 0);
  const possibleScore = getStateParam('possible_score', 0);
  const timePerQuestion = getStateParam('timePerQuestion', []);
  
  // Get authentication token from Redux store
  const userInfo = useSelector(state => state.userLogin.userInfo);
  const access_token = userInfo ? userInfo.access : null;
  
  useEffect(() => {
    const fetchReviewData = async () => {
      setLoading(true);
      try {
        if (!assessmentResponseId) {
          console.error('No assessment response ID provided');
          throw new Error('No assessment response ID provided');
        }

        console.log('Assessment review initialization - ID:', assessmentResponseId);
        console.log('Parameters:', { isCatBased, score, possibleScore, abilityScore });

        // API request structure
        const apiRequest = async () => {
          console.log('Attempting to fetch assessment data from API at:', `/api/assessment_response/${assessmentResponseId}`);
          console.log('Using access token:', access_token ? 'Yes (token available)' : 'No');
          try {
            const response = await api.get(`/api/assessment_response/${assessmentResponseId}`, {
              headers: { Authorization: `Bearer ${access_token}` }
            });
            console.log('API response successful:', response.status);
            return response.data;
          } catch (error) {
            // Check for specific error codes
            if (error.response) {
              console.error(`API returned error status: ${error.response.status}`, error.response.data);
              
              // For 404 (Not Found) or 401 (Unauthorized), we can continue with minimal data
              if (error.response.status === 404 || error.response.status === 401) {
                console.log('API returned 404/401 - will proceed with minimal data display');
                return { questions: [] }; // Return empty questions array to trigger minimal view
              }
            } else {
              console.error('API request error details:', error.message);
            }
            throw error;
          }
        };
        
        // Get the data either from API or use what we have
        let reviewData;
        
        // Regular assessment - score based
        if (!isCatBased) {
          console.log('Processing regular assessment review');
          
          try {
            const apiData = await apiRequest();
            console.log('API data received:', apiData);
            
            reviewData = {
              questions: apiData.questions || [],
              score: score,
              possible_score: possibleScore,
              isCatBased: false
            };
            
            // Calculate correct answers
            if (apiData.questions && Array.isArray(apiData.questions)) {
              const correctAnswers = apiData.questions.filter(q => 
                q.userAnswer === q.correctAnswer
              ).length;
              setCorrectCount(correctAnswers);
            }
          } catch (apiError) {
            console.error('API request failed for regular assessment:', apiError);
            // Fall back to score-only display if API fails
            reviewData = {
              questions: [],
              score: score,
              possible_score: possibleScore,
              isCatBased: false
            };
            setCorrectCount(score);
          }
        } 
        // CAT assessment - ability score based
        else {
          console.log('Processing CAT assessment review');
          
          try {
            const apiData = await apiRequest();
            console.log('API data received for CAT:', apiData);
            
            reviewData = {
              questions: apiData.questions || [],
              abilityScore: abilityScore,
              timePerQuestion: timePerQuestion,
              isCatBased: true
            };
            
            // Calculate correct answers
            if (apiData.questions && Array.isArray(apiData.questions)) {
              const correctAnswers = apiData.questions.filter(q => 
                q.userAnswer === q.correctAnswer
              ).length;
              setCorrectCount(correctAnswers);
            }
          } catch (apiError) {
            console.error('API request failed for CAT:', apiError);
            // Fall back to ability score display if API fails
            reviewData = {
              questions: [],
              abilityScore: abilityScore,
              timePerQuestion: timePerQuestion,
              isCatBased: true
            };
          }
        }
        
        console.log('Setting review data:', reviewData);
        setAssessmentReview(reviewData);
        
      } catch (error) {
        console.error('Error in fetchReviewData:', error);
        setError(error.message || 'Failed to load assessment review');
      } finally {
        console.log('Setting loading to false');
        setLoading(false);
      }
    };

    // Call the fetch function
    fetchReviewData();
  }, [assessmentResponseId, isCatBased, abilityScore, timePerQuestion, access_token, score, possibleScore]);

  // If loading for more than 10 seconds, show a timeout error
  useEffect(() => {
    let timeoutId;
    
    if (loading) {
      timeoutId = setTimeout(() => {
        console.log('Loading timeout - API request seems to be taking too long');
        setLoading(false);
        setError('Loading timed out. The API request is taking too long or failed.');
      }, 10000); // 10 second timeout
    }
    
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [loading]);

  // Calculate the percentage score
  const calculatePercentage = () => {
    if (!assessmentreview) return 0;
    
    // For CAT assessments
    if (assessmentreview.isCatBased) {
      if (assessmentreview.abilityScore) {
        // Map ability score (typically -3 to +3) to percentage (0-100)
        const normalizedScore = (assessmentreview.abilityScore + 3) / 6;
        return Math.min(Math.max(Math.round(normalizedScore * 100), 0), 100);
      }
    }
    
    // For regular assessments
    if (assessmentreview.score !== undefined && assessmentreview.possible_score) {
      return Math.round((assessmentreview.score / assessmentreview.possible_score) * 100);
    }
    
    // Fallback to questions-based calculation
    if (assessmentreview.questions && assessmentreview.questions.length > 0) {
      return Math.round((correctCount / assessmentreview.questions.length) * 100);
    }
    
    return 0;
  };

  // Get score color based on percentage
  const getScoreColor = (percent) => {
    if (percent >= 80) return '#4CAF50';
    if (percent >= 60) return '#FFC107';
    return '#F44336';
  };

  // Handler for printing the results
  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <div className="assessment-review-container loading-container">
        <div className="loading-spinner"></div>
        <p>Loading assessment results...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="assessment-review-container error-container">
        <h2>Error</h2>
        <p>{error}</p>
        <Link to="/AdaptiveAssessments" className="return-button">Return to Assessments</Link>
      </div>
    );
  }

  const percentage = calculatePercentage();
  const scoreColor = getScoreColor(percentage);

  // If no data at all, handle it
  if (!assessmentreview) {
    return (
      <div className="assessment-review-container error-container">
        <h2>No Data Available</h2>
        <p>Could not find any assessment results to display.</p>
        <Link to="/AdaptiveAssessments" className="return-button">Return to Assessments</Link>
      </div>
    );
  }

  // Even if we have minimal data (just score), show something
  const showMinimalView = !assessmentreview.questions || assessmentreview.questions.length === 0;
  
  return (
    <div className="assessment-review-container">
      <h2 className="review-header">Assessment Review</h2>

      {/* Summary Section */}
      <div className="assessment-summary">
        <div className="score-chart">
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              textSize: '22px',
              pathColor: scoreColor,
              textColor: scoreColor,
              trailColor: '#e6e6e6',
            })}
          />
        </div>
        
        <div className="score-details">
          <h3 className="score-title">Your Score</h3>
          {assessmentreview.isCatBased ? (
            <p className="score-text">
              Your estimated ability level is <strong>{assessmentreview.abilityScore?.toFixed(2) || 'Not available'}</strong>
            </p>
          ) : (
            <p className="score-text">
              You scored <strong>{assessmentreview.score || 0}</strong> out of <strong>{assessmentreview.possible_score || 0}</strong> points.
            </p>
          )}
        </div>

        {assessmentreview.isCatBased && assessmentreview.abilityScore && (
          <div className="ability-score">
            <h3 className="ability-title">Ability Score</h3>
            <div className="ability-value">{assessmentreview.abilityScore.toFixed(2)}</div>
            <p className="ability-message">
              A score of approximately 2.5 is an A student, 1.5 is an average student. Try again to keep improving if you are not satisfied with your score.
            </p>
          </div>
        )}
        
        {/* Show a message if we're only showing minimal data */}
        {showMinimalView && (
          <div className="minimal-view-notice">
            <p>Detailed question review is not available, but your score has been recorded.</p>
          </div>
        )}
      </div>

      {/* Questions Review Section - only show if we have questions */}
      {!showMinimalView && (
        <div className="questions-review">
          <h3 className="questions-title">Questions Review</h3>
          <ul className="review-list">
            {assessmentreview.questions.map((question, index) => {
              // Get question properties, handling different property names
              const questionText = question.text || question.question || '';
              const questionImage = question.image || '';
              const userAnswer = question.userAnswer || question.answer || '';
              const correctAnswer = question.correctAnswer || question.correct_answer || '';
              const isCorrect = userAnswer === correctAnswer;
              
              return (
                <li key={question.id || index} className={`review-item ${isCorrect ? 'correct' : 'incorrect'}`}>
                  <div className="question-header">
                    <span className="question-number">Q{index + 1}:</span>
                    <span className={`status-indicator ${isCorrect ? 'correct' : 'incorrect'}`}>
                      {isCorrect ? 'Correct' : 'Incorrect'}
                    </span>
                  </div>
                  
                  <div className="question-content">
                    {questionImage && (
                      <div className="question-image">
                        <img src={questionImage} alt={`Question ${index + 1}`} />
                      </div>
                    )}
                    <p className="question-text">{questionText}</p>
                  </div>
                  
                  <div className="answer-details">
                    <div className="user-answer">
                      <strong>Your Answer:</strong> {userAnswer || 'No answer provided'}
                    </div>
                    {!isCorrect && (
                      <div className="correct-answer">
                        <strong>Correct Answer:</strong> {correctAnswer}
                      </div>
                    )}
                    {assessmentreview.timePerQuestion && assessmentreview.timePerQuestion[index] && (
                      <div className="time-taken">
                        <strong>Time Taken:</strong> {Math.floor(assessmentreview.timePerQuestion[index] / 60)}:{String(assessmentreview.timePerQuestion[index] % 60).padStart(2, '0')} minutes
                      </div>
                    )}
                    {question.explanation && (
                      <div className="explanation">
                        <strong>Explanation:</strong> {question.explanation}
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {/* Action Buttons */}
      <div className="action-buttons">
        <Link to="/AdaptiveAssessments" className="return-button">Return to Assessments</Link>
        <button onClick={handlePrint} className="print-button">Print Results</button>
      </div>
    </div>
  );
};

export default AssessmentResponsePage;
