import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import './acidbasechem.css'
const MODEL_COUNT = 10;
const isMobile = window.innerWidth < 768; // Adjust threshold if needed
const phone_size_molecule = 0.035;
const computer_size_molecule= 0.1;
const moleculesize = isMobile ? phone_size_molecule : computer_size_molecule; 
const MOBILE_PRESET_POSITIONS = [
  [ -25,  60,   3],  [  -5,  60,  -4],  [  15,  60,  -9],
  [ -25,  30,  -2],  [  -5,  30,   1],  [  15,  30,   6],
  [ -25,  10, -1.5], [  -5,  10,   4],  [  15,  10,   1],
  [  -5, -10,   0]   // Central reference molecule
];
const DESKTOP_PRESET_POSITIONS = [
  [ -60,  80,   3],  [   0,  80,  -4],  [  60,  80,  -9],
  [ -60,  40,  -2],  [   0,  40,   1],  [  60,  40,   6],
  [ -60,   0, -1.5], [   0,   0,   4],  [  60,   0,   1],
  [   0, -40,   0]   // Central reference molecule
];

const PRESET_POSITIONS = isMobile ? MOBILE_PRESET_POSITIONS : DESKTOP_PRESET_POSITIONS;

const AcidBaseScene = () => {
  const mountRef = useRef(null);
  const modelRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [pH, setPH] = useState(2.4);
  const [pKa, setPka] = useState(4.76);

  const conjugateRatio = Math.pow(10, pH - pKa);
  const deprotonatedCount = Math.round((conjugateRatio / (1 + conjugateRatio)) * MODEL_COUNT);

  let animationFrameId = null;

  useEffect(() => {
    let clock = new THREE.Clock();
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("#BFEFFF");

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 200);
    camera.position.set(0, 10, 100);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    mountRef.current.innerHTML = "";
    mountRef.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enablePan = true;
    controls.enableZoom = true;
    controls.minDistance = 5;
    controls.maxDistance = 200;
    controls.target.set(0, 0, 0);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    directionalLight.position.set(5, 5, 5);
    directionalLight.castShadow = true;
    scene.add(directionalLight);
{/*
const loader = new GLTFLoader();
  loader.load(
  "beaker.glb",
  (gltf) => {
    console.log("✅ Beaker Loaded Successfully", gltf.scene);
    const beaker = gltf.scene;
    beaker.scale.set(100, 50, 70);
    beaker.position.set(20, 10, 0);
    scene.add(beaker);
  },
  undefined,
  (error) => {
    console.error("❌ Beaker Load Error:", error);
  }
);
 */}   const loadFBXModel = (modelPath, position) => {
      setLoading(true);
      const loader = new FBXLoader();
      loader.load(
        modelPath,
        (fbx) => {
          fbx.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;
            }
          });

          fbx.scale.set(moleculesize,moleculesize,moleculesize);
          fbx.position.set(...position);

          scene.add(fbx);
          modelRefs.current.push(fbx);

          setLoading(false);
        },
        undefined,
        (error) => {
          console.error(`Error loading model: ${modelPath}`, error);
          setLoading(false);
        }
      );
    };

    modelRefs.current = [];
    for (let i = 0; i < MODEL_COUNT; i++) {
      loadFBXModel("NEW2.fbx", PRESET_POSITIONS[i]);
    }

    const animate = () => {
      const delta = clock.getDelta();
      controls.update();
      renderer.render(scene, camera);
      animationFrameId = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  const animateMorphTarget = (mesh, index, targetValue, duration = 1000) => {
    let startTime = performance.now();
    let startValue = mesh.morphTargetInfluences[index];

    const update = (time) => {
      let elapsed = time - startTime;
      let progress = Math.min(elapsed / duration, 1);
      mesh.morphTargetInfluences[index] = startValue + (targetValue - startValue) * progress;

      if (progress < 1) {
        requestAnimationFrame(update);
      }
    };

    requestAnimationFrame(update);
  };

  useEffect(() => {
    if (!modelRefs.current.length) return;

    modelRefs.current.forEach((fbx, index) => {
      fbx.traverse((child) => {
        if (child.isMesh && child.morphTargetDictionary && child.morphTargetInfluences) {
          const morphIndex = child.morphTargetDictionary["Key 1"];
          if (morphIndex !== undefined) {
            const targetValue = index < deprotonatedCount ? 1.0 : 0.0;
            animateMorphTarget(child, morphIndex, targetValue);
          }
        }
      });
    });
  }, [pH, pKa]);

  return (
    <div style={{ 
      display: "flex", flexDirection: "column", alignItems: "center", 
      justifyContent: "center", width: "100vw", minHeight: "100vh", 
      background: "#F4F6F8", padding: "5vh" 
    }}>
      <header>
        <h1 style={{ fontSize: "4vw", fontWeight: "bold", marginBottom: "2vh", }}>Henderson Hasselbach Equation</h1>
      </header>

      <div style={{ background: "#ffffff", padding: "3vh", borderRadius: "10px", width: "80%", maxWidth: "80%",boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  textAlign: "center", marginBottom: "2vh" }}>
        <h2 style={{ fontSize: "3vw", fontWeight: "bold" }}>Understanding pH & pKa</h2>
        <p style={{ fontSize: "2vw" }}>
          <strong>pKa</strong> is a measure of acidity and represents the pH at which <strong>50% of a species is deprotonated</strong>.
        </p>
      </div>
<div style={{
  background: "#FFFFFF",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  maxWidth: "80%",
  textAlign: "center",
  marginBottom: "20px"
}}>
  <h2 style={{ fontSize: "22px", fontWeight: "bold", marginBottom: "10px" }}>
    How to Use This Simulator
  </h2>
  <p style={{ fontSize: "16px", lineHeight: "1.5", marginBottom: "10px" }}>
    Adjust the **pH** and **pKa** sliders to see how the number of 
    **protonated** and **deprotonated** molecules change in real time.
  </p>
  <p style={{ fontSize: "16px", lineHeight: "1.5", marginBottom: "10px" }}>
    The **Henderson-Hasselbalch (HH) equation** updates automatically:
  </p>
  <p style={{ 
    fontSize: "18px", 
    fontWeight: "bold", 
    background: "#F4F6F8", 
    padding: "10px", 
    borderRadius: "5px"
  }}>
    pH = pKa + log([A⁻] / [HA])
  </p>
  <p style={{ fontSize: "16px", lineHeight: "1.5", marginBottom: "10px" }}>
    This equation calculates the ratio of **deprotonated (A⁻)** to **protonated (HA)** molecules,
    dynamically changing based on your inputs.
  </p>
  <p style={{ fontSize: "16px", lineHeight: "1.5", marginBottom: "10px" }}>
    - **If pH &lt; pKa** → Most molecules are **protonated** (HA).  
    - **If pH = pKa** → **50%** are protonated, **50%** deprotonated.  
    - **If pH &gt; pKa** → Most molecules are **deprotonated** (A⁻).
  </p>
  <p style={{ fontSize: "16px", lineHeight: "1.5", marginBottom: "10px" }}>
    You can use **mouse controls** to explore the scene:
  </p>
  <ul style={{ fontSize: "16px", lineHeight: "1.5", textAlign: "left", paddingLeft: "20px" }}>
    <li><strong>Left Click + Drag:</strong> Rotate the view.</li>
    <li><strong>Right Click + Drag:</strong> Pan the camera.</li>
    <li><strong>Scroll:</strong> Zoom in and out.</li>
  </ul>
</div>
      <div style={{ background: "#ffffff", padding: "3vh", borderRadius: "10px", width: "80%", maxWidth: "80%", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
 textAlign: "center" }}>
        <h2 style={{ fontSize: "3vw", fontWeight: "bold" }}>Henderson-Hasselbalch Equation</h2>
        <p style={{ fontSize: "2.5vw", fontWeight: "bold" }}>pH = pKa + log([A⁻]/[HA])</p>
        <p style={{ fontSize: "2.5vw", fontWeight: "bold", color: "#E63946" }}>[A⁻] / [HA] = 10^({pH.toFixed(2)} - {pKa.toFixed(2)}) = {conjugateRatio.toFixed(2)}</p>
        <p style={{ fontSize: "2.5vw", fontWeight: "bold", color: "#2A9D8F" }}>Deprotonated Molecules: {deprotonatedCount} / {MODEL_COUNT}</p>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "2vh", width: "80%" }}>
        <label style={{ fontSize: "2vw", fontWeight: "bold" }}>pH: {pH.toFixed(2)}</label>
        <input type="range" min="0" max="14" step="0.1" value={pH} onChange={(e) => setPH(parseFloat(e.target.value))} style={{ width: "80%" }} />
        <label style={{ fontSize: "2vw", fontWeight: "bold" }}>pKa: {pKa.toFixed(2)}</label>
        <input type="range" min="0" max="14" step="0.1" value={pKa} onChange={(e) => setPka(parseFloat(e.target.value))} style={{ width: "80%" }} />
      </div>

      <div ref={mountRef}></div>
    </div>
  );
};

export default AcidBaseScene;
