import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './BlogPost.css';  // Import the CSS file

const BlogPost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  useEffect(() => {
    fetchPost();
  }, []);

  const fetchPost = async () => {
    try {
      const response = await axios.get(`https://api.andrewslearning.com/api/posts/${postId}/`);
      console.log('Fetched post data:', response.data);
      setPost(response.data);
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  const handleDeletePost = async () => {
    try {
      await axios.delete(`https://api.andrewslearning.com/api/posts/${postId}/`);
      console.log('Post deleted successfully');
      // Handle deletion success
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  if (!post) {
    return (
      <div className="loading">
        Loading...
      </div>
    );
  }

  // Create a DOMParser instance to sanitize and render HTML content
  const parser = new DOMParser();
  const sanitizedContent = parser.parseFromString(post.content, 'text/html').body.innerHTML;

  console.log('Sanitized content:', sanitizedContent);

  return (
    <div className="blog-container">
      <h2 className="blog-title" id="Posts">{post.title}</h2>
      <div className="blog-content" dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>
     {/* <button className="delete-button" onClick={handleDeletePost}>Delete Post</button>*/}
      <br/>
      <br/>
    </div>
  );
};

export default BlogPost;
