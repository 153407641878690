import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import * as math from 'mathjs';
import '../App.css';
import MathKeyboard from './MathKeyboard'; // Import the keyboard
import './Algebralab3.css'; // We'll create this new CSS file

const FunctionGraph = () => {
    const [func, setFunc] = useState('x^2');
    const [yValues, setYValues] = useState([]);
    const [range, setRange] = useState({ xStart: -10, xEnd: 10 });
    const [error, setError] = useState('');
    const [history, setHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [theme, setTheme] = useState('light');
    const graphRef = useRef(null);

    // Function to evaluate and graph
    useEffect(() => {
        try {
            const xValues = Array.from({ length: 500 }, (_, i) => range.xStart + i * (range.xEnd - range.xStart) / 500);
            const newFunc = math.compile(func);
            const newYValues = xValues.map(x => {
                try {
                    return newFunc.evaluate({ x });
                } catch (e) {
                    return null;
                }
            });
            setYValues(newYValues);
            setError('');
        } catch (e) {
            console.error('Invalid function', e);
            setError('Invalid function. Please check your syntax.');
        }
    }, [func, range]);

    // Handle zoom controls
    const handleZoomIn = () => setRange({ xStart: range.xStart / 2, xEnd: range.xEnd / 2 });
    const handleZoomOut = () => setRange({ xStart: range.xStart * 2, xEnd: range.xEnd * 2 });
    const handleReset = () => setRange({ xStart: -10, xEnd: 10 });

    // Handle keyboard input
    const handleKeyPress = (key) => {
        if (key === '<<') {
            setFunc(func.slice(0, -1)); // Backspace
        } else {
            setFunc(func + key);
        }
    };

    // Save function to history
    const saveToHistory = () => {
        if (func && !error) {
            const newHistory = [...history];
            if (!newHistory.includes(func)) {
                newHistory.unshift(func);
                if (newHistory.length > 10) newHistory.pop();
                setHistory(newHistory);
            }
        }
    };

    // Load function from history
    const loadFromHistory = (savedFunc) => {
        setFunc(savedFunc);
        setShowHistory(false);
    };

    // Toggle theme
    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    // Prepare plot data
    const xValues = Array.from({ length: 500 }, (_, i) => range.xStart + i * (range.xEnd - range.xStart) / 500);

    // Plot color settings based on theme
    const plotColors = {
        light: {
            background: '#FFFFFF',
            text: '#333333',
            grid: 'rgba(200, 200, 200, 0.3)',
            line: 'rgb(64, 95, 237)',
        },
        dark: {
            background: '#1E1E1E',
            text: '#E0E0E0',
            grid: 'rgba(100, 100, 100, 0.3)',
            line: 'rgb(92, 208, 255)',
        }
    };

    const currentColors = plotColors[theme];

    return (
        <div className={`algebra-lab ${theme}`}>
            <div className="algebra-header">
                <h1>Advanced Function Visualizer</h1>
                <button className="theme-toggle" onClick={toggleTheme}>
                    {theme === 'light' ? '🌙' : '☀️'}
                </button>
            </div>

            <div className="algebra-content">
                <div className="graph-panel">
                    <div className="function-input-container">
                        <div className="function-field">
                            <label htmlFor="function-input">f(x) =</label>
                            <input 
                                id="function-input"
                                type="text" 
                                value={func} 
                                onChange={(e) => setFunc(e.target.value)} 
                                placeholder="Enter a function..." 
                            />
                            <button 
                                className="graph-function-btn" 
                                onClick={saveToHistory}
                                disabled={!!error}
                            >
                                Graph
                            </button>
                        </div>
                        {error && <div className="error-message">{error}</div>}
                    </div>

                    <div className="graph-container" ref={graphRef}>
                        <Plot
                            data={[
                                {
                                    x: xValues,
                                    y: yValues,
                                    type: 'scatter',
                                    mode: 'lines',
                                    line: { color: currentColors.line, width: 2.5 },
                                    name: func
                                }
                            ]}
                            layout={{
                                width: '100%',
                                height: 400,
                                title: {
                                    text: `Graph of y = ${func}`,
                                    font: {
                                        family: 'Poppins, sans-serif',
                                        size: 20,
                                        color: currentColors.text
                                    }
                                },
                                plot_bgcolor: currentColors.background,
                                paper_bgcolor: currentColors.background,
                                font: {
                                    family: 'Poppins, sans-serif',
                                    color: currentColors.text
                                },
                                xaxis: {
                                    title: 'x',
                                    showgrid: true,
                                    zeroline: true,
                                    zerolinecolor: currentColors.grid,
                                    gridcolor: currentColors.grid,
                                    linecolor: currentColors.text,
                                    tickcolor: currentColors.text,
                                },
                                yaxis: {
                                    title: 'y',
                                    showline: true,
                                    zeroline: true,
                                    zerolinecolor: currentColors.grid,
                                    gridcolor: currentColors.grid,
                                    linecolor: currentColors.text,
                                    tickcolor: currentColors.text,
                                },
                                margin: {
                                    t: 50, r: 30, b: 50, l: 50
                                },
                                showlegend: false,
                            }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "100%" }}
                            config={{
                                displayModeBar: true,
                                displaylogo: false,
                                responsive: true,
                                toImageButtonOptions: {
                                    format: 'png',
                                    filename: `graph_${func}`,
                                    scale: 2
                                }
                            }}
                        />
                    </div>

                    <div className="graph-controls">
                        <div className="zoom-controls">
                            <button onClick={handleZoomIn} title="Zoom In">
                                <span>+</span>
                            </button>
                            <button onClick={handleReset} title="Reset View">
                                <span>⟳</span>
                            </button>
                            <button onClick={handleZoomOut} title="Zoom Out">
                                <span>−</span>
                            </button>
                        </div>
                        <div className="range-info">
                            <span>x: [{range.xStart}, {range.xEnd}]</span>
                        </div>
                    </div>
                </div>

                <div className="side-panel">
                    <div className="history-panel">
                        <button 
                            className="history-toggle" 
                            onClick={() => setShowHistory(!showHistory)}
                        >
                            {showHistory ? 'Hide History' : 'Show History'}
                        </button>
                        
                        {showHistory && (
                            <div className="history-list">
                                <h4>Recent Functions</h4>
                                {history.length === 0 ? (
                                    <p className="no-history">No history yet</p>
                                ) : (
                                    <ul>
                                        {history.map((item, index) => (
                                            <li key={index}>
                                                <button onClick={() => loadFromHistory(item)}>
                                                    {item}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="keyboard-panel">
                        <h4>Math Keyboard</h4>
                        <MathKeyboard onKeyPress={handleKeyPress} theme={theme} />
                    </div>

                    <div className="tips-panel">
                        <h4>Function Tips</h4>
                        <ul>
                            <li>Use <code>x</code> as the variable</li>
                            <li>For powers, use <code>^</code> (e.g., <code>x^2</code>)</li>
                            <li>Use <code>sin(x)</code>, <code>cos(x)</code>, <code>tan(x)</code> for trigonometry</li>
                            <li>Use <code>sqrt(x)</code> for square root</li>
                            <li>Examples: <code>x^2 + 2*x - 1</code>, <code>sin(x) * cos(x)</code></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="algebra-footer">
                <p>Advanced Function Visualizer - Powered by Plotly & Math.js</p>
            </div>
        </div>
    );
}

export default FunctionGraph;
