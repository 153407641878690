import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './video.css'; // Ensure this path matches your CSS file's location

const VideoDetail = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  function getEmbedUrl(url) {
    if (!url) return '';
    try {
      // Handle different YouTube URL formats
      let videoId = '';
      
      // Handle standard watch URLs
      if (url.includes('youtube.com/watch')) {
        videoId = url.split('v=')[1];
      }
      // Handle shortened youtu.be URLs
      else if (url.includes('youtu.be/')) {
        videoId = url.split('youtu.be/')[1];
      }
      // Handle embed URLs
      else if (url.includes('youtube.com/embed/')) {
        videoId = url.split('embed/')[1];
      }
      
      // Clean up the video ID by removing any additional parameters
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }
      
      // Remove any trailing slashes
      videoId = videoId.replace('/', '');
      
      if (!videoId) {
        console.error('Could not extract video ID from URL:', url);
        return '';
      }
      
      return `https://www.youtube.com/embed/${videoId}`;
    } catch (err) {
      console.error('Error processing video URL:', err);
      return '';
    }
  }

  useEffect(() => {
    setLoading(true);
    
    // Fetch the main video
    fetch(`https://api.andrewslearning.com/api/get_video/${videoId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      })
      .then(data => {
        setVideo(data);
        // After getting the main video, fetch related videos
        return fetch('https://api.andrewslearning.com/api/all_lectures');
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      })
      .then(allVideos => {
        // Filter to get related videos (excluding current video)
        let related = allVideos
          .filter(v => v.id !== parseInt(videoId))
          .slice(0, 4); // Limit to 4 related videos
        setRelatedVideos(related);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error loading video data:', err);
        setError(err.toString());
        setLoading(false);
      });
  }, [videoId]);

  if (loading) {
    return (
      <div className="video-page-container">
        <div className="video-loading">
          <div className="loading-spinner"></div>
          <p>Loading video...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="video-page-container">
        <div className="video-error">
          <p>Error loading video: {error}</p>
          <Link to="/videos" className="back-to-videos">Return to Videos</Link>
        </div>
      </div>
    );
  }

  if (!video) {
    return (
      <div className="video-page-container">
        <div className="video-error">
          <p>Video not found</p>
          <Link to="/videos" className="back-to-videos">Return to Videos</Link>
        </div>
      </div>
    );
  }

  const embedUrl = getEmbedUrl(video.video);
  if (!embedUrl) {
    return (
      <div className="video-page-container">
        <div className="video-error">
          <p>Invalid video URL format. Please check the video link.</p>
          <Link to="/videos" className="back-to-videos">Return to Videos</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="video-page-container">
      <div className="video-main-content">
        <div className="video-wrapper">
          <div className="video-content">
            <div className="video-metadata">
              <h1 className="video-title">{video.title}</h1>
              <p className="video-description">{video.description}</p>
            </div>
            <div className="video-container">
              <iframe
                src={embedUrl}
                title="Embedded Video"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video-frame"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      
      <div className="video-sidebar">
        <h3 className="related-videos-title">Related Videos</h3>
        <div className="related-videos-list">
          {relatedVideos.length > 0 ? (
            relatedVideos.map(relatedVideo => (
              <Link 
                to={`/video/${relatedVideo.id}`} 
                key={relatedVideo.id} 
                className="related-video-item"
              >
                <div className="related-video-thumbnail">
                  <img 
                    src={relatedVideo.videoimage || "https://via.placeholder.com/120x80"} 
                    alt={relatedVideo.title} 
                  />
                </div>
                <div className="related-video-info">
                  <h4 className="related-video-title">{relatedVideo.title}</h4>
                </div>
              </Link>
            ))
          ) : (
            <p className="no-related-videos">No related videos found</p>
          )}
        </div>
        <Link to="/videos" className="back-to-videos">Browse All Videos</Link>
      </div>
    </div>
  );
};

export default VideoDetail;
