import React, { useEffect, useRef, useState } from "react";

const pdbFiles = [
  "/pdb_files/relaxedfinal.pdb", // R-State (Relaxed Hemoglobin)
  "/pdb_files/tensefinal.pdb", // T-State (Tense Hemoglobin)
];

// Fixed colour assignments for consistency
const chainColors = {
  A: "cyan",
  B: "red",
  C: "cyan",
  D: "red",
};

const ThreeDMolViewer = () => {
  const viewerRef = useRef(null);
  const viewerInstance = useRef(null);
  const [currentPdbIndex, setCurrentPdbIndex] = useState(0);
  const [autoSwitch, setAutoSwitch] = useState(false);
  const autoSwitchInterval = useRef(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    scriptRef.current = script;
    script.src = "/3Dmol.js";
    script.async = true;
    script.onload = () => {
      if (window.$3Dmol) {
        if (!viewerInstance.current) {
          viewerInstance.current = new window.$3Dmol.createViewer(viewerRef.current, {
            backgroundColor: "white",
          });
        }
        loadPdb(pdbFiles[currentPdbIndex]);
      }
    };
    document.body.appendChild(script);

    return () => {
      if (scriptRef.current && scriptRef.current.parentNode) {
        scriptRef.current.parentNode.removeChild(scriptRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (viewerInstance.current) {
      loadPdb(pdbFiles[currentPdbIndex]);
    }
  }, [currentPdbIndex]);

  useEffect(() => {
    if (autoSwitch) {
      autoSwitchInterval.current = setInterval(() => {
        setCurrentPdbIndex((prevIndex) => (prevIndex + 1) % pdbFiles.length);
      }, 1500);
    } else {
      clearInterval(autoSwitchInterval.current);
    }

    return () => clearInterval(autoSwitchInterval.current);
  }, [autoSwitch]);

  const loadPdb = (pdbFile) => {
    fetch(pdbFile)
      .then((response) => response.text())
      .then((pdbData) => {
        const viewer = viewerInstance.current;
        viewer.removeAllModels();
        viewer.addModel(pdbData, "pdb");

        // **Apply fixed chain colours**
        Object.keys(chainColors).forEach((chain) => {
          viewer.setStyle({ chain: chain }, { cartoon: { color: chainColors[chain] } });
          viewer.setStyle({ resn: "HIS", chain: chain, resi: [87, 92] }, { stick: { color: chainColors[chain], radius: 0.3 } });
        });

        // **Heme group (HEM) - Sticks and spheres**
        viewer.setStyle(
          { resn: "HEM" },
          { stick: { colorscheme: "Jmol" }, sphere: { radius: 0.2, color: "purple" } }
        );

        // **Iron atoms (Fe in heme) - Bright red spheres**
        viewer.setStyle({ resn: "HEM", elem: "FE" }, { sphere: { radius: 2, color: "red" } });

        // **Oxygen molecules (O2 bound to heme) - Blue spheres**
        viewer.setStyle({ resn: "OXY" }, { sphere: { radius: 1.0, color: "blue" } });

        viewer.center();
        viewer.render();
      })
      .catch((error) => console.error("Error loading PDB file:", error));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
        backgroundColor: "#f8f9fa",
        padding: "20px",
      }}
    >
      {/* 3D Viewer Container */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          maxWidth: "800px",
          height: "500px",
          border: "2px solid #ccc",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <div ref={viewerRef} style={{ width: "100%", height: "100%" }}></div>
      </div>

      {/* Control Buttons */}
      <div style={{ display: "flex", gap: "10px" }}>
        <button
          onClick={() => setCurrentPdbIndex((prevIndex) => (prevIndex + 1) % pdbFiles.length)}
          style={{
            padding: "12px 20px",
            fontSize: "18px",
            fontWeight: "bold",
            cursor: "pointer",
            borderRadius: "8px",
            background: "#007bff",
            color: "white",
            border: "none",
            boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
            transition: "background 0.3s ease",
          }}
        >
          Switch Structure
        </button>

        <button
          onClick={() => setAutoSwitch(true)}
          style={{
            padding: "12px 20px",
            fontSize: "18px",
            fontWeight: "bold",
            cursor: "pointer",
            borderRadius: "8px",
            background: autoSwitch ? "#6c757d" : "#28a745",
            color: "white",
            border: "none",
            boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
            transition: "background 0.3s ease",
          }}
          disabled={autoSwitch}
        >
          Start Auto-Switch
        </button>

        <button
          onClick={() => setAutoSwitch(false)}
          style={{
            padding: "12px 20px",
            fontSize: "18px",
            fontWeight: "bold",
            cursor: "pointer",
            borderRadius: "8px",
            background: "#dc3545",
            color: "white",
            border: "none",
            boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
            transition: "background 0.3s ease",
          }}
        >
          Stop Auto-Switch
        </button>
      </div>

      {/* Explanation Box */}
      <div
        style={{
          maxWidth: "800px",
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <h2 style={{ color: "#2c3e50", fontFamily: "Arial, sans-serif" }}>Hemoglobin: R-State vs. T-State</h2>
        <p style={{ fontSize: "16px", lineHeight: "1.6", color: "#34495e" }}>
          Hemoglobin is a **tetrameric** protein composed of two **alpha** and two **beta** subunits. It plays a crucial role in transporting oxygen throughout the body.
        </p>
        <h3 style={{ color: "#e74c3c" }}>R-State (Relaxed State)</h3>
        <p>
          The **R-state** has a higher affinity for oxygen. When oxygen binds to one subunit, the hemoglobin undergoes **allosteric changes**, making it easier for additional oxygen molecules to bind.
        </p>
        <h3 style={{ color: "#3498db" }}>T-State (Tense State)</h3>
        <p>
          The **T-state** is the low-affinity form, stabilised by interactions between the subunits. When oxygen is released, hemoglobin shifts back to the T-state, favouring oxygen unloading in tissues.
        </p>
        <p>
          The **cooperative binding** of oxygen allows hemoglobin to be highly efficient in oxygen transport and release.
        </p>
      </div>
      <br/>
    </div>
  );
};

export default ThreeDMolViewer;

