import { useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Helper function to detect mobile devices including iPhones
const detectMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // iOS detection (iPhone, iPad, iPod)
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  
  // Android detection
  const isAndroid = /android/i.test(userAgent);
  
  // General mobile detection
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) ||
                  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // Modern iPad detection
  
  return {
    isIOS,
    isAndroid,
    isMobile
  };
};

// Helper function to get browser information
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";
  let browserVersion = "Unknown";
  
  try {
    // Extract browser name and version
    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
      browserVersion = userAgent.match(/Firefox\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
      browserName = "Samsung Browser";
      browserVersion = userAgent.match(/SamsungBrowser\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      browserName = "Opera";
      browserVersion = userAgent.match(/(?:Opera|OPR)\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Edg") > -1) {
      browserName = "Edge";
      browserVersion = userAgent.match(/Edg\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
      browserVersion = userAgent.match(/Chrome\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
      browserVersion = userAgent.match(/Version\/([0-9.]+)/)?.[1] || 
                        userAgent.match(/Safari\/([0-9.]+)/)[1];
    }
  } catch (e) {
    console.error("Error detecting browser info:", e);
  }
  
  return {
    browserName,
    browserVersion,
    userAgent
  };
};

// Function to get the session cookie
const getSessionCookie = () => {
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith('session_id='));
  return cookie ? cookie.split('=')[1] : null;
};

// Function to set a session cookie if one doesn't exist
const setSessionCookie = () => {
  const deviceInfo = detectMobileDevice();
  const sessionId = uuidv4();
  // For iOS/Safari we set a slightly different cookie to ensure compatibility
  const cookieOptions = deviceInfo.isIOS ? 
    `session_id=${sessionId}; path=/; SameSite=Lax` : 
    `session_id=${sessionId}; path=/; SameSite=Strict; Secure`;
    
  document.cookie = cookieOptions;
  return sessionId;
};

// Function to check if the device is in low power mode (estimated)
const isLowPowerMode = () => {
  // We can't directly detect Low Power Mode, but we can make an educated guess
  // based on battery level if the Battery API is available
  if ('getBattery' in navigator) {
    navigator.getBattery().then(battery => {
      return battery.level <= 0.2; // Assume low power mode if battery is below 20%
    }).catch(() => {
      return false;
    });
  }
  return false;
};

// Function to check if connection is metered
const isMeteredConnection = () => {
  if ('connection' in navigator) {
    const conn = navigator.connection;
    if (conn && conn.saveData) {
      return true; // Data Saver is enabled
    }
    if (conn && conn.type) {
      // Consider cellular connections as metered
      return ['cellular', '2g', '3g', '4g', '5g'].includes(conn.type);
    }
  }
  return false; // Default to false if we can't determine
};

const PageVisitTracker = ({ pageName, pageId, additionalParams = {} }) => {
  const deviceInfo = detectMobileDevice();
  const browserInfo = getBrowserInfo();

  useEffect(() => {
    // Only track if user has accepted cookies
    const consentGiven = localStorage.getItem('cookies_accepted');
    if (consentGiven !== 'true') return;
    
    // Get or create session ID
    let sessionId = getSessionCookie();
    if (!sessionId) {
      sessionId = setSessionCookie();
    }
    
    // Track the page visit
    const trackPageVisit = async () => {
      try {
        // Get IP address (this is just for client-side; the server will also determine IP)
        let ipAddress = '';
        try {
          const ipResponse = await axios.get('https://api64.ipify.org?format=json');
          ipAddress = ipResponse.data.ip;
        } catch (error) {
          console.error('Error fetching IP:', error);
        }
        
        // Build the tracking data
        const pageData = {
          session_id: sessionId,
          page_url: window.location.href,
          page_path: pageName || window.location.pathname,
          timestamp: new Date().toISOString(),
          ip_address: ipAddress,
          referrer_url: document.referrer || 'Direct',
          // Device information
          user_agent: navigator.userAgent,
          device_type: deviceInfo.isMobile ? 'Mobile' : 'Desktop',
          device_os: deviceInfo.isIOS ? 'iOS' : 
                   deviceInfo.isAndroid ? 'Android' :
                   navigator.platform.includes('Win') ? 'Windows' :
                   navigator.platform.includes('Mac') ? 'MacOS' :
                   navigator.platform.includes('Linux') ? 'Linux' : 'Unknown',
          browser_name: browserInfo.browserName,
          browser_version: browserInfo.browserVersion,
          screen_width: window.innerWidth,
          screen_height: window.innerHeight,
          is_mobile: deviceInfo.isMobile,
          is_ios: deviceInfo.isIOS,
          is_android: deviceInfo.isAndroid,
          ...additionalParams
        };
        
        // Send the tracking data to the backend
        await axios.post('https://api.andrewslearning.com/api/track-page/', pageData, {
          withCredentials: true,
        });
        
        // Set up the page unload tracking
        const handleUnload = async () => {
          const endTime = new Date();
          const startTime = new Date(pageData.timestamp);
          const timeSpent = Math.round((endTime - startTime) / 1000);
          
          // Update time spent
          pageData.time_spent = timeSpent;
          
          // For iOS and Safari, use a regular AJAX request instead of sendBeacon
          if (deviceInfo.isIOS || browserInfo.browserName === 'Safari') {
            try {
              // Use a more efficient approach for mobile iOS
              if (deviceInfo.isIOS && navigator.sendBeacon) {
                // Some modern iOS Safari versions support sendBeacon
                const url = 'https://api.andrewslearning.com/api/track-page/';
                const blob = new Blob([JSON.stringify(pageData)], { type: 'application/json' });
                const success = navigator.sendBeacon(url, blob);
                
                // Fall back to axios if sendBeacon fails
                if (!success) {
                  await axios.post('https://api.andrewslearning.com/api/track-page/', pageData, {
                    withCredentials: true,
                  });
                }
              } else {
                // Use regular axios for older iOS Safari
                await axios.post('https://api.andrewslearning.com/api/track-page/', pageData, {
                  withCredentials: true,
                });
              }
            } catch (error) {
              console.error('Error tracking page unload (iOS/Safari):', error);
            }
          } else if (navigator.sendBeacon) {
            const url = 'https://api.andrewslearning.com/api/track-page/';
            const blob = new Blob([JSON.stringify(pageData)], { type: 'application/json' });
            navigator.sendBeacon(url, blob);
          } else {
            try {
              await axios.post('https://api.andrewslearning.com/api/track-page/', pageData, {
                withCredentials: true,
              });
            } catch (error) {
              console.error('Error tracking page unload:', error);
            }
          }
        };
        
        // Different event binding approach for iOS/Safari
        if (deviceInfo.isIOS || browserInfo.browserName === 'Safari') {
          // For iOS, use pagehide which is more reliable than beforeunload
          window.addEventListener('pagehide', handleUnload);
          // Also use visibilitychange as a fallback
          document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
              handleUnload();
            }
          });
        } else {
          window.addEventListener('beforeunload', handleUnload);
        }
        
        // Determine heartbeat interval based on device type, battery and connection
        // We want to be more conservative with resources on mobile devices
        let heartbeatInterval = 60000; // Default: 60 seconds for desktop
        
        if (deviceInfo.isMobile) {
          heartbeatInterval = 120000; // 2 minutes for mobile
          
          // Check for low power mode or metered connection
          if (isLowPowerMode() || isMeteredConnection()) {
            heartbeatInterval = 300000; // 5 minutes if battery low or metered connection
          }
          
          // Special case for iOS
          if (deviceInfo.isIOS) {
            // iOS Safari often restricts background processing
            heartbeatInterval = 180000; // 3 minutes for iOS
          }
        }
        
        // Set up heartbeat tracking for page activity monitoring
        let startTime = new Date();
        let lastInteractionTime = new Date();
        let isActive = true;
        
        // Listen for user interaction to track active usage
        const userInteractionEvents = ['click', 'scroll', 'keypress', 'mousemove', 'touchstart'];
        const handleUserInteraction = () => {
          lastInteractionTime = new Date();
          isActive = true;
        };
        
        // Add interaction listeners
        userInteractionEvents.forEach(eventType => {
          window.addEventListener(eventType, handleUserInteraction, { passive: true });
        });
        
        const heartbeatTimer = setInterval(async () => {
          try {
            // Check if the user is still active
            const currentTime = new Date();
            const idleTime = Math.round((currentTime - lastInteractionTime) / 1000);
            
            // Consider user inactive after 5 minutes of no interaction
            if (idleTime > 300) {
              isActive = false;
            }
            
            // Only send heartbeat if the page is actively being used
            if (isActive) {
              // Send heartbeat with current page activity duration
              const timeSpent = Math.round((currentTime - startTime) / 1000);
              
              const heartbeatData = {
                ...pageData,
                time_spent: timeSpent
              };
              
              await axios.post('https://api.andrewslearning.com/api/track-page/heartbeat/', heartbeatData, {
                withCredentials: true,
              });
            }
          } catch (error) {
            console.error('Error sending heartbeat:', error);
          }
        }, heartbeatInterval);
        
        // Cleanup function to remove event listeners and clear interval
        return () => {
          // Remove page unload tracking
          if (deviceInfo.isIOS || browserInfo.browserName === 'Safari') {
            window.removeEventListener('pagehide', handleUnload);
            document.removeEventListener('visibilitychange', handleUnload);
          } else {
            window.removeEventListener('beforeunload', handleUnload);
          }
          
          // Remove user interaction tracking
          userInteractionEvents.forEach(eventType => {
            window.removeEventListener(eventType, handleUserInteraction);
          });
          
          clearInterval(heartbeatTimer);
          
          // Final tracking on component unmount
          handleUnload();
        };
      } catch (error) {
        console.error('Error in page visit tracking:', error);
      }
    };
    
    trackPageVisit();
  }, [pageName, pageId, additionalParams]); // Run effect when these props change
  
  // This component doesn't render anything visible
  return null;
};

export default PageVisitTracker; 