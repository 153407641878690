import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import TypingEffect from "./TypingEffect";
import "./HomePage.css";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-1XD9QJLJGR"; // Replace with your GA4 Measurement ID
ReactGA.initialize(TRACKING_ID);

const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    // Smooth scroll for anchor links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }, [location]);

  return (
    <div className="homepage-container">
      {/* 🔹 WELCOME SECTION */}
      <Section 
        text="Need to Pass? We've Got You." 
        titleClassName="welcome-heading" 
        className="section-welcome"
        delay={100}
      >
        <p className="section-description">
          Struggling with complex topics? Our platform simplifies science, medicine, and math so you retain information 
          and ace your exams. Stop cramming. Start mastering.
        </p>
        <Button href="/About#About" variant="primary" className="pulse-button">I Need to Pass My Exams</Button>
      </Section>

      {/* 🔹 TESTIMONIAL SECTION */}
      <Section 
        text="Real Students. Real Success." 
        titleClassName="testimonial-heading" 
        className="section-testimonial"
        delay={300}
      >
        <p className="testimonial-intro">
          Students just like you are using this platform to pass exams and master their courses.
        </p>
        <div className="testimonial">
          <blockquote>"My students could really use something like this! The interactive components help learners visualize difficult concepts." - Dr. R</blockquote>
          <blockquote>"This is really cool thank you for your help! You really helped me get through pharmacology! I've improved my grade by two letter grades." - Sonja</blockquote>
        </div>
      </Section>

      {/* 🔹 INTERACTIVE TOOLS SECTION */}
      <Section 
        text="Struggling with Science? We Make It Click." 
        titleClassName="tools-heading" 
        className="section-tools"
        delay={200}
      >
        <p className="section-description">
          Memorising isn't enough. Our virtual labs, interactive visuals, and real-world case studies help you truly 
          understand chemistry, biology, pharmacology, and more—so you actually retain what you learn.
        </p>
        <div className="video-container">
          <video
            src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/Homepageproteingif.mov"
            alt="Interactive Protein Visualization"
            className="section-video"
            autoPlay
            loop
            muted
            playsInline
          ></video>
        </div>
      </Section>

      {/* 🔹 TOP-DOWN LEARNING SECTION */}
      <Section 
        text="Overwhelmed? Learn Smarter, Not Harder." 
        titleClassName="top-down-heading" 
        className="section-top-down"
        delay={400}
      >
        <p className="section-description">
          Drowning in notes? We use Top-Down Learning—start with big-picture concepts, then master the details. 
          This is how future doctors, nurses, and scientists learn smarter—not harder.
        </p>
        <div className="video-container">
          <video
            src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/momentumgif.mov"
            alt="Physics Momentum Simulation"
            className="section-video"
            autoPlay
            loop
            muted
            playsInline
          ></video>
        </div>
      </Section>

      {/* 🔹 REAL-WORLD APPLICATIONS SECTION */}
      <Section 
        text="Exam Questions Won't Be Straightforward. Neither is Learning." 
        titleClassName="real-life-heading" 
        className="section-real-life"
        delay={300}
      >
        <p className="section-description">
          Med school professors won't spoon-feed you. We prepare you with real-life applications and problem-solving 
          so you can handle tricky questions under pressure.
        </p>
        <div className="video-container">
          <video
            src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/algebrafunctiongif.mov"
            alt="Algebra Function Visualization"
            className="section-video"
            autoPlay
            loop
            muted
            playsInline
          ></video>
        </div>
      </Section>

      {/* 🔹 STEM CAREER SECTION */}
      <Section 
        text="Want to Be a Doctor, Nurse, or Pharmacist? Start Here." 
        titleClassName="stem-heading" 
        className="section-stem"
        delay={200}
      >
        <p className="section-description">
          You're not just studying for an exam—you're preparing for your future. Whether you want to be a physician, 
          nurse, or biomedical scientist, this is where you start thinking like a professional.
        </p>
        <div className="cta-container">
          <Button className="button-homepage" href="/Apply" variant="success">Get Started Now</Button>
        </div>
      </Section>
    </div>
  );
};

const Section = ({ text, titleClassName, children, className, delay = 0 }) => {
  const { ref, inView } = useInView({ 
    triggerOnce: true,
    threshold: 0.2,
    rootMargin: '-50px 0px'
  });
  
  const sectionStyle = {
    transitionDelay: `${delay}ms`
  };

  return (
    <div 
      ref={ref} 
      className={`fade-in-section full-width-section ${inView ? "is-visible" : ""} ${className}`}
      style={sectionStyle}
    >
      <TypingEffect text={text} className={`typing-effect ${titleClassName}`} speed={40} isVisible={inView} />
      {children}
    </div>
  );
};

export default HomePage;

