import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../actions/userActions';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Profile.css';
import FriendsList from './friendsList';
import { FaUser, FaEdit, FaSignOutAlt } from 'react-icons/fa';
import PrivateChat from './PrivateChat';

const Profile = () => {
  const { id } = useParams();
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const isUserDetailsFetched = useRef(false);
  const [activeChat, setActiveChat] = useState(null);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else if (!isUserDetailsFetched.current && userInfo.token) {
      dispatch(getUserDetails(id));
      isUserDetailsFetched.current = true;
    }
  }, [dispatch, navigate, userInfo, id]);

  useEffect(() => {
    const fetchQuestionsAnswered = async () => {
      if (!userInfo?.token) return null;
  
      try {
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
        const { data } = await axios.get('https://api.andrewslearning.com/api/questions_answered', config);
        const correct = data.correct_responses || 0;
        const incorrect = data.incorrect_responses || 0;
        setCorrectAnswers(correct);
        setIncorrectAnswers(incorrect);
        setQuestionsAnswered(correct + incorrect);
        return data;
      } catch (error) {
        console.error('Error fetching questions answered:', error.message);
        return null;
      }
    };
  
    if (userDetails.user) {
      fetchQuestionsAnswered().then(data => {
        if (data) {
          console.log("Fetched questions data:", data);
        }
      });
    }
  }, [userInfo, userDetails.user]);

  const handleCancelSubscription = async () => {
    if (!userInfo?.token) return;
    try {
      const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
      const response = await axios.post(
        'https://api.andrewslearning.com/api/cancel_subscription',
        { user_id: userInfo.id },
        config
      );

      alert(response.data?.detail ? 'Subscription canceled successfully.' : 'Failed to cancel subscription. Please try again.');
    } catch (error) {
      console.error('Error canceling subscription:', error.message);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="profile-page-container">
      <div className="profile-container">
        <div className="profile-header">
          <div className="profile-pic-placeholder">
            {userDetails.user?.Propic ? (
              <img src={userDetails.user.Propic} alt="Profile" />
            ) : (
              <FaUser size={60} color="#3b82f6" />
            )}
          </div>
        </div>
        
        <div className="profile-info">
          <h2 className="username-display">{userDetails.user?.username || 'User'}</h2>
          
          <div className="profile-stats">
            <div className="stat-card">
              <div className="stat-label">Streak</div>
              <div className="stat-value">{userDetails.user?.streak || 0}</div>
            </div>
            
            <div className="stat-card">
              <div className="stat-label">Questions</div>
              <div className="stat-value">{questionsAnswered}</div>
            </div>
            
            <div className="stat-card">
              <div className="stat-label">Correct</div>
              <div className="stat-value">{correctAnswers}</div>
            </div>
          </div>
          
          <p className="profile-email">
            <strong>Email</strong>
            {userDetails.user?.email || 'No email provided'}
          </p>
          
          <div className="profile-buttons">
            <button className="update-button">
              <FaEdit /> Update Profile
            </button>
            
            <button className="cancel-button" onClick={handleCancelSubscription}>
              <FaSignOutAlt /> Cancel Subscription
            </button>
          </div>
        </div>
      </div>

      {/* Friends List Column */}
      <div className="friends-list-container">
        <div className="friends-list-title">Friends</div>
        <FriendsList onChatSelect={setActiveChat} />
      </div>
      {activeChat && <PrivateChat activeChat={activeChat} />}
    </div>
  );
};

export default Profile;
