import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { logout } from '../actions/userActions';
import useSound from '../Sound';
import '../Header.css';
import { NeumorphicButton } from './NeumorphicButton';

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  
  // Redux state
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  
  // Sound effects - adding a debounce to prevent multiple rapid clicks
  const { play: originalPlayClickSound } = useSound('/ui-click-43196.mp3');
  const [lastClickTime, setLastClickTime] = useState(0);
  
  // Debounced click sound to prevent freezing
  const playClickSound = () => {
    const now = Date.now();
    const timeSinceLastClick = now - lastClickTime;
    
    // Only play sound if it's been at least 200ms since the last click
    if (timeSinceLastClick > 200) {
      originalPlayClickSound();
      setLastClickTime(now);
    }
  };
  
  // Logout handler
  const logoutHandler = () => {
    dispatch(logout());
    playClickSound();
    setUserMenuOpen(false);
  };
  
  useEffect(() => {
    setIsVisible(true);
    
    // Add scroll effects
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      
      // Parallax effect for background
      const header = document.querySelector('.stem-header');
      if (header) {
        header.style.backgroundPositionY = `${scrollPosition * 0.2}px`;
      }
      
      // Show/hide navbar background based on scroll position
      if (scrollPosition > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    const newState = !mobileMenuOpen;
    setMobileMenuOpen(newState);
    
    // Delay the play sound slightly to avoid freezing
    setTimeout(() => {
      playClickSound();
    }, 50);
    
    // Toggle body class to prevent background scrolling
    if (newState) {
      document.body.classList.add('menu-open');
      // Focus the first interactive element in the menu
      setTimeout(() => {
        const firstLink = document.querySelector('.navbar-links .nav-link');
        if (firstLink) {
          firstLink.focus();
        }
      }, 100);
    } else {
      document.body.classList.remove('menu-open');
      // Return focus to the menu toggle button
      const menuToggle = document.querySelector('.mobile-menu-toggle');
      if (menuToggle) {
        menuToggle.focus();
      }
    }
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
    playClickSound();
  };

  // Close user menu if user clicks outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (userMenuOpen && !e.target.closest('.user-menu')) {
        setUserMenuOpen(false);
      }
    };
    
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [userMenuOpen]);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (mobileMenuOpen && !e.target.closest('.navbar-links') && !e.target.closest('.mobile-menu-toggle')) {
        setMobileMenuOpen(false);
        document.body.classList.remove('menu-open');
      }
    };
    
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [mobileMenuOpen]);

  // Handle escape key to close mobile menu
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape' && mobileMenuOpen) {
        setMobileMenuOpen(false);
        document.body.classList.remove('menu-open');
        const menuToggle = document.querySelector('.mobile-menu-toggle');
        if (menuToggle) {
          menuToggle.focus();
        }
      }
    };
    
    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [mobileMenuOpen]);

  // Clean up body classes and styles when component unmounts
  useEffect(() => {
    return () => {
      document.body.classList.remove('menu-open');
      document.body.style.touchAction = 'auto';
    };
  }, []);

  return (
    <>
      <nav className={`stem-navbar ${isScrolled ? 'scrolled' : ''}`} role="navigation" aria-label="Main navigation">
        <div className="navbar-container">
          <div className="navbar-logo">
            <Link to="/" aria-label="Home">
              <img 
                src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/ALClogo.png"
                alt="Andrew's Learning"
                className="navbar-logo-img"
                onClick={playClickSound}
              />
            </Link>
          </div>
          
          <div className={`navbar-links ${mobileMenuOpen ? 'mobile-active' : ''}`} role="menu">
            <Link to="/" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/'; }, 100); }}>Home</Link>
            <Link to="/AdaptiveAssessments" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/AdaptiveAssessments'; }, 100); }}>Assessments</Link>
            <Link to="/InteractiveLabs" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/InteractiveLabs'; }, 100); }}>Labs</Link>
            <Link to="/Courses" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/Courses'; }, 100); }}>Courses</Link>
            <Link to="/Videos" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/Videos'; }, 100); }}>Videos</Link>
            <Link to="/arcade" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/arcade'; }, 100); }}>Arcade</Link>
            <Link to="/Contact" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/Contact'; }, 100); }}>Contact</Link>
            <Link to="/Blog" className="nav-link" role="menuitem" onClick={(e) => { e.preventDefault(); e.stopPropagation(); playClickSound(); setMobileMenuOpen(false); setTimeout(() => { window.location.href = '/Blog'; }, 100); }}>Blog</Link>
            
            <NeumorphicButton 
              isLink={true} 
              to="/founder-letter"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                playClickSound();
                setMobileMenuOpen(false);
                setTimeout(() => {
                  window.location.href = '/founder-letter';
                }, 100);
              }}
            >
              Our Vision
            </NeumorphicButton>
          </div>
          
          <div className="navbar-actions">
            {userInfo ? (
              <div className="user-menu">
                <button 
                  className="user-menu-button" 
                  onClick={toggleUserMenu}
                  aria-expanded={userMenuOpen}
                  aria-haspopup="true"
                >
                  {userInfo.username}
                  <span className={`dropdown-arrow ${userMenuOpen ? 'open' : ''}`}>▼</span>
                </button>
                
                {userMenuOpen && (
                  <div className="user-dropdown" role="menu" aria-label="User menu">
                    <LinkContainer to={`/Profile/${userInfo.id}`}>
                      <Link 
                        className="dropdown-item" 
                        role="menuitem"
                        onClick={(e) => { 
                          e.stopPropagation(); 
                          playClickSound(); 
                        }}
                      >
                        Profile
                      </Link>
                    </LinkContainer>
                    <button 
                      className="dropdown-item" 
                      role="menuitem"
                      onClick={logoutHandler}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Link to="/Login" id="LoginButton"className="nav-button secondary-nav-button" onClick={playClickSound} role="menuitem">Log In</Link>
                <Link to="/Register" className="nav-button primary-nav-button" onClick={playClickSound} role="menuitem">Register</Link>
              </>
            )}
            
            {userInfo && !user?.has_active_subscription && (
              <Link to="/Cart" className="nav-button premium-nav-button" onClick={playClickSound} role="menuitem">
                Premium
                {cartItems.length > 0 && (
                  <span className="cart-badge" aria-label={`${cartItems.length} items in cart`}>{cartItems.length}</span>
                )}
              </Link>
            )}
          </div>
          
          <button 
            className={`mobile-menu-toggle ${mobileMenuOpen ? 'active' : ''}`} 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleMobileMenu();
            }}
            aria-expanded={mobileMenuOpen}
            aria-controls="navbar-links"
            aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}
          >
            <span className="menu-icon"></span>
          </button>
        </div>
      </nav>

      <header className={`stem-header ${isVisible ? 'visible' : ''}`}>
        <div className="stem-header-background">
          <div className="stem-header-overlay"></div>
        </div>
        
        <div className="stem-header-content">
          <div className="stem-header-logo">
            <img 
              src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/ALClogo.png"
              alt="Andrew's Learning"
              className="stem-logo"
            />
          </div>
          
          <div className="stem-header-text">
            <h1 className="stem-title">STEM Education <span>Reimagined</span></h1>
            <p className="stem-subtitle">
              Interactive learning tools designed for science, technology, engineering, and mathematics
            </p>
            
            <div className="stem-header-features">
              <div className="feature-item">
                <div className="feature-icon">🔬</div>
                <div className="feature-text">
                  <h3>Interactive Labs</h3>
                  <p>Hands-on virtual experiments across all STEM disciplines</p>
                </div>
              </div>
              
              <div className="feature-item">
                <div className="feature-icon">📊</div>
                <div className="feature-text">
                  <h3>Adaptive Learning</h3>
                  <p>Personalized assessments that evolve with your progress</p>
                </div>
              </div>
              
              <div className="feature-item">
                <div className="feature-icon">🚀</div>
                <div className="feature-text">
                  <h3>Applied Knowledge</h3>
                  <p>Connect theory with real-world applications</p>
                </div>
              </div>
            </div>
            
            <div className="stem-header-actions">
              <Link to="/AdaptiveAssessments#Assessments" className="primary-button" onClick={playClickSound}>
                Start Learning
              </Link>
              <Link to="/InteractiveLabs" className="secondary-button" onClick={playClickSound}>
                Explore Labs
              </Link>
            </div>
          </div>
          
          <div className="stem-header-visual">
            <div className="visual-container">
              <div className="visual-element">
                <video
                  src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Gifs/spinningTF.mp4"
                  className="header-video"
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
              <div className="pulse-circle"></div>
            </div>
            
            <div className="scroll-indicator">
              <span>Scroll to explore</span>
              <div className="scroll-arrow"></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
