import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// Animation for slide-in effect
const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  z-index: 999; /* Below the cookie banner */
`;

const CookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #007bff;
  color: white;
  padding: 1.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Above the overlay */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.5);
  animation: ${slideUp} 0.5s ease-out;
  border-top: 5px solid #ff5722;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
    font-size: 1rem;
  }
`;

const BannerText = styled.div`
  margin: 0;
  flex: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Arial', sans-serif;
  
  @media (max-width: 768px) {
    margin-bottom: 1rem;
    text-align: center;
    justify-content: center;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const Button = styled.button`
  background-color: white;
  color: #007bff;
  border: 2px solid white;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0f2ff;
    color: #0056b3;
  }
  
  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    flex: 1;
  }
`;

// Helper function to detect mobile devices
const detectMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // iOS detection - specifically for iPhone, iPad, iPod
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  
  // Android detection
  const isAndroid = /android/i.test(userAgent);
  
  // General mobile detection
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent) ||
                  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // iPadOS detection
  
  return {
    isIOS,
    isAndroid,
    isMobile
  };
};

// Helper function to get browser information
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";
  let browserVersion = "Unknown";
  
  try {
    // Extract browser name and version
    if (userAgent.indexOf("Firefox") > -1) {
      browserName = "Firefox";
      browserVersion = userAgent.match(/Firefox\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
      browserName = "Samsung Browser";
      browserVersion = userAgent.match(/SamsungBrowser\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      browserName = "Opera";
      browserVersion = userAgent.match(/(?:Opera|OPR)\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Edg") > -1) {
      browserName = "Edge";
      browserVersion = userAgent.match(/Edg\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserName = "Chrome";
      browserVersion = userAgent.match(/Chrome\/([0-9.]+)/)[1];
    } else if (userAgent.indexOf("Safari") > -1) {
      browserName = "Safari";
      browserVersion = userAgent.match(/Version\/([0-9.]+)/)?.[1] || 
                       userAgent.match(/Safari\/([0-9.]+)/)[1];
    }
  } catch (e) {
    console.error("Error detecting browser info:", e);
  }
  
  return {
    browserName,
    browserVersion,
    userAgent
  };
};

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [ipData, setIpData] = useState({ ip: null });
  const location = useLocation();
  let startTime = new Date();
  const deviceInfo = detectMobileDevice();
  const browserInfo = getBrowserInfo();

  // Function to set the session cookie
  const setSessionCookie = () => {
    const sessionId = uuidv4();
    
    // For iOS, use a different cookie setting approach to improve compatibility
    const cookieOptions = deviceInfo.isIOS ? 
      `session_id=${sessionId}; path=/; SameSite=Lax` : 
      `session_id=${sessionId}; path=/; SameSite=Strict; Secure`;
      
    document.cookie = cookieOptions;
    console.log('Session ID set:', sessionId);
    return sessionId;
  };

  // Function to get the session cookie
  const getSessionCookie = () => {
    const cookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('session_id='));
    return cookie ? cookie.split('=')[1] : null;
  };

  // Fetch the user's IP address
  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      setIpData({ ip: response.data.ip });
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  // Initialize session and IP data
  useEffect(() => {
    fetchIpAddress();
    const consentGiven = localStorage.getItem('cookies_accepted');
    if (!consentGiven) {
      setShowBanner(true);
    } else if (consentGiven === 'true' && !getSessionCookie()) {
      setSessionCookie();
    }
  }, []);

  // Heartbeat tracking for active users
  useEffect(() => {
    // Only track if user has consented
    const consentGiven = localStorage.getItem('cookies_accepted');
    if (consentGiven !== 'true') return;
    
    const sessionId = getSessionCookie();
    if (sessionId && ipData.ip) {
      const sendHeartbeat = async () => {
        const pageData = {
          session_id: sessionId,
          page_url: window.location.href,
          page_path: location.pathname,
          ip_address: ipData.ip,
          timestamp: new Date().toISOString(),
          referrer_url: document.referrer || 'Direct',
          // Add device and browser information
          user_agent: navigator.userAgent,
          device_type: deviceInfo.isMobile ? 'Mobile' : 'Desktop',
          device_os: deviceInfo.isIOS ? 'iOS' : 
                   deviceInfo.isAndroid ? 'Android' :
                   navigator.platform.includes('Win') ? 'Windows' :
                   navigator.platform.includes('Mac') ? 'MacOS' :
                   navigator.platform.includes('Linux') ? 'Linux' : 'Unknown',
          browser_name: browserInfo.browserName,
          browser_version: browserInfo.browserVersion,
          screen_width: window.innerWidth,
          screen_height: window.innerHeight,
          is_mobile: deviceInfo.isMobile,
          is_ios: deviceInfo.isIOS,
          is_android: deviceInfo.isAndroid
        };

        try {
          await axios.post(`https://api.andrewslearning.com/api/track-page/heartbeat`, pageData, {
            withCredentials: true,
          });
        } catch (error) {
          console.error('Error sending heartbeat:', error);
        }
      };

      // We're not using a heartbeat interval here anymore
      // This is now handled by the PageVisitTracker component 
      // for better resource management
      
      // Just send one initial heartbeat
      sendHeartbeat();
    }
  }, [ipData.ip, location.pathname]);

  // Handle session tracking on page unload
  useEffect(() => {
    const consentGiven = localStorage.getItem('cookies_accepted');
    const sessionId = getSessionCookie();

    if (consentGiven === 'true' && sessionId) {
      const handleUnload = async () => {
        const endTime = new Date();
        const timeSpent = Math.round((endTime - startTime) / 1000);

        const pageData = {
          session_id: sessionId,
          page_url: window.location.href,
          page_path: location.pathname,
          timestamp: startTime.toISOString(),
          time_spent: timeSpent,
          ip_address: ipData.ip,
          referrer_url: document.referrer || 'Direct',
          // Add device and browser information
          user_agent: navigator.userAgent,
          device_type: deviceInfo.isMobile ? 'Mobile' : 'Desktop',
          device_os: deviceInfo.isIOS ? 'iOS' : 
                   deviceInfo.isAndroid ? 'Android' :
                   navigator.platform.includes('Win') ? 'Windows' :
                   navigator.platform.includes('Mac') ? 'MacOS' :
                   navigator.platform.includes('Linux') ? 'Linux' : 'Unknown',
          browser_name: browserInfo.browserName,
          browser_version: browserInfo.browserVersion,
          screen_width: window.innerWidth,
          screen_height: window.innerHeight,
          is_mobile: deviceInfo.isMobile,
          is_ios: deviceInfo.isIOS,
          is_android: deviceInfo.isAndroid
        };

        // This unload tracking is now primarily handled by PageVisitTracker
        // This is just a backup in case PageVisitTracker fails or doesn't work
        
        // Special handling for iOS/Safari
        if (deviceInfo.isIOS || browserInfo.browserName === 'Safari') {
          try {
            await axios.post(`https://api.andrewslearning.com/api/track-page/`, pageData, {
              withCredentials: true,
            });
          } catch (error) {
            console.error('Error tracking page view (iOS/Safari):', error);
          }
        } else if (navigator.sendBeacon) {
          const url = `https://api.andrewslearning.com/api/track-page/`;
          const blob = new Blob([JSON.stringify(pageData)], { type: 'application/json' });
          navigator.sendBeacon(url, blob);
        } else {
          try {
            await axios.post(`https://api.andrewslearning.com/api/track-page/`, pageData, {
              withCredentials: true,
            });
          } catch (error) {
            console.error('Error tracking page view:', error);
          }
        }
      };

      // Different event binding approach for iOS/Safari
      if (deviceInfo.isIOS || browserInfo.browserName === 'Safari') {
        // For iOS, use pagehide which is more reliable than beforeunload
        window.addEventListener('pagehide', handleUnload);
        // Also use visibilitychange as a fallback
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'hidden') {
            handleUnload();
          }
        });
      } else {
        window.addEventListener('beforeunload', handleUnload);
      }

      return () => {
        handleUnload(); // Call on component unmount
        
        if (deviceInfo.isIOS || browserInfo.browserName === 'Safari') {
          window.removeEventListener('pagehide', handleUnload);
          document.removeEventListener('visibilitychange', handleUnload);
        } else {
          window.removeEventListener('beforeunload', handleUnload);
        }
      };
    }
  }, [location, ipData]);

  // Handle acceptance of cookies
  const handleAccept = () => {
    localStorage.setItem('cookies_accepted', 'true');
    const sessionId = setSessionCookie();
    setShowBanner(false);
    
    // Initial page visit tracking on acceptance
    if (sessionId && ipData.ip) {
      const pageData = {
        session_id: sessionId,
        page_url: window.location.href,
        page_path: location.pathname,
        ip_address: ipData.ip,
        timestamp: new Date().toISOString(),
        referrer_url: document.referrer || 'Direct',
        // Device information
        user_agent: navigator.userAgent,
        device_type: deviceInfo.isMobile ? 'Mobile' : 'Desktop',
        device_os: deviceInfo.isIOS ? 'iOS' : 
                 deviceInfo.isAndroid ? 'Android' :
                 navigator.platform.includes('Win') ? 'Windows' :
                 navigator.platform.includes('Mac') ? 'MacOS' :
                 navigator.platform.includes('Linux') ? 'Linux' : 'Unknown',
        browser_name: browserInfo.browserName,
        browser_version: browserInfo.browserVersion,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        is_mobile: deviceInfo.isMobile,
        is_ios: deviceInfo.isIOS,
        is_android: deviceInfo.isAndroid
      };
      
      // On accept, directly send the first tracking info
      axios.post(`https://api.andrewslearning.com/api/track-page/`, pageData, {
        withCredentials: true,
      }).catch(error => {
        console.error('Error sending initial page tracking data:', error);
      });
      
      // Force reload the page to ensure all tracking components are activated
      // This is especially important for mobile devices
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  // Handle decline of cookies
  const handleDecline = () => {
    localStorage.setItem('cookies_accepted', 'false');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <>
        <Overlay /> {/* Add the dark overlay */}
        <CookieBanner>
          <BannerText>
            <Icon src="https://cdn-icons-png.flaticon.com/512/2917/2917995.png" alt="Flask Icon" />
            We use cookies to improve your experience.
            
          </BannerText>
          <ButtonContainer>
            <Button onClick={handleAccept}>Accept</Button>
            <Button onClick={handleDecline}>Decline</Button>
          </ButtonContainer>
        </CookieBanner>
      </>
    )
  );
};

export default CookieConsent;
