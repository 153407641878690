import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, getUserDetails } from "../actions/userActions";
import "./AdaptiveAssessments.css";
import ReactGA from "react-ga4";

// Google Analytics Initialization
const TRACKING_ID = "G-1XD9QJLJGR";
ReactGA.initialize(TRACKING_ID);

function AdaptiveAssessments() {
  const [assessments, setAssessments] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const access_token = userInfo ? userInfo.access : null;
  const successMessage = useSelector((state) => state.cart.successMessage);
  const failMessage = useSelector((state) => state.cart.failMessage);
  const prevSuccessMessageRef = useRef();

  // Track page views on component mount
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/adaptive-assessments" });
  }, []);

  useEffect(() => {
    const prevSuccessMessage = prevSuccessMessageRef.current;
    if (successMessage && successMessage !== prevSuccessMessage) {
      alert(successMessage);
    }
    prevSuccessMessageRef.current = successMessage;
  }, [successMessage]);

  useEffect(() => {
    if (failMessage) {
      alert(failMessage);
      navigate("/Register#RegistrationForm");
    }
  }, [failMessage, navigate]);

  useEffect(() => {
    const fetchAssessments = async () => {
      setLoading(true);
      
      // Use XMLHttpRequest instead of fetch to avoid CORS issues
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://api.andrewslearning.com/api/assessments/', true);
      
      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            setAssessments(data);
            
            // Extract unique categories from assessments
            const uniqueCategories = [...new Set(data.map(assessment => 
              assessment.name.includes("MCAT") ? "MCAT" : 
              assessment.name.includes("Nursing") ? "Nursing" :
              assessment.name.includes("NCLEX") ? "NCLEX" :
              assessment.name.includes("Medicine") ? "Medicine" : "Other"
            ))];
            setCategories(uniqueCategories);
          } catch (e) {
            console.error("Error parsing JSON:", e);
            setError("Error parsing data from server");
          }
        } else {
          setError(`HTTP error! status: ${xhr.status}`);
        }
        setLoading(false);
      };
      
      xhr.onerror = function() {
        console.error("Network error occurred");
        setError("Network error occurred. Please check your connection.");
        setLoading(false);
      };
      
      xhr.send();
    };

    fetchAssessments();
  }, []);

  useEffect(() => {
    if (!user || !user.id) {
      const userId = userInfo ? userInfo.id : null;
      if (userId) {
        dispatch(getUserDetails(userId));
      }
    }
  }, [dispatch, userInfo, user]);

  const startAssessment = async (assessment) => {
    if (!access_token) {
      alert("Must be logged in to start assessments");
      return;
    }

    // Use XMLHttpRequest for consistency with fetch method
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `https://api.andrewslearning.com/api/start_assessment/${assessment.id}/`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    
    xhr.onload = function() {
      if (xhr.status === 200) {
        try {
          const data = JSON.parse(xhr.responseText);
          
          // Log event when an assessment is started
          ReactGA.event({
            category: "Assessments",
            action: "Started Assessment",
            label: assessment.name,
          });

          if (assessment.CAT) {
            navigate(`/catassessments/${assessment.id}`, {
              state: { assessmentResponseId: data.assessment_response_id },
            });
          } else {
            navigate(`/assessments/${assessment.id}`, {
              state: { assessmentResponseId: data.assessment_response_id },
            });
          }
        } catch (e) {
          console.error("Error parsing response:", e);
          alert("Error starting assessment. Please try again.");
        }
      } else {
        console.error("Failed to start assessment with status:", xhr.status);
        alert("Failed to start assessment. Please try again later.");
      }
    };
    
    xhr.onerror = function() {
      console.error("Network error when starting assessment");
      alert("Network error. Please check your connection and try again.");
    };
    
    xhr.send();
  };

  const renderButton = (assessment) => {
    if (!user || Object.keys(user).length === 0) {
      if (!assessment.isPaid) {
        return (
          <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>
            Start Free Assessment
          </button>
        );
      } else {
        return (
          <button
            className="add-to-cart-button"
            onClick={() => {
              ReactGA.event({
                category: "Subscription",
                action: "Clicked Register for Paid Assessment",
                label: assessment.name,
              });
              navigate("/Register#RegistrationForm");
            }}
          >
            Subscribe to Access
          </button>
        );
      }
    } else {
      if (!assessment.isPaid) {
        return (
          <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>
            Start Free Assessment
          </button>
        );
      } else {
        if (user.has_active_subscription) {
          return (
            <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>
              Start Assessment
            </button>
          );
        } else {
          return (
            <button
              className="add-to-cart-button"
              onClick={() => {
                ReactGA.event({
                  category: "Subscription",
                  action: "Clicked Become a Premium Member",
                  label: assessment.name,
                });
                navigate("/Cart");
              }}
            >
              Subscribe for Access
            </button>
          );
        }
      }
    }
  };

  const getAssessmentCategory = (assessment) => {
    if (assessment.name.includes("MCAT")) return "MCAT";
    if (assessment.name.includes("Nursing")) return "Nursing";
    if (assessment.name.includes("NCLEX")) return "NCLEX";
    if (assessment.name.includes("Medicine")) return "Medicine";
    return "Other";
  };

  const filteredAssessments = assessments.filter((assessment) => {
    const matchesSearch = assessment.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === "all" || getAssessmentCategory(assessment) === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const renderAssessmentContent = () => {
    return (
      <div className="adaptive-container">
        <div className="hero-section">
          <div className="hero-content">
            <h1>Personalized Medical Assessment Platform</h1>
            <p>Powered by Ada, our adaptive learning algorithm tailored for medical education</p>
          </div>
          <div className="hero-image">
            <img id="Ada" src="https://andrewslearningimage-bucket.s3.amazonaws.com/Ada.png" alt="Ada AI" />
          </div>
        </div>
        
        <div className="irt-cat-intro">
          <h2>Evidence-Based Medical Learning</h2>
          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon">📊</div>
              <h3>Item Response Theory</h3>
              <p>Leveraging psychometric principles to precisely measure your knowledge and abilities in medical subjects</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">🧠</div>
              <h3>Adaptive Learning</h3>
              <p>Questions adapt to your performance, focusing on knowledge gaps for efficient studying</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">📈</div>
              <h3>Performance Analytics</h3>
              <p>Detailed insights into your strengths and areas for improvement across medical topics</p>
            </div>
          </div>
        </div>
        
        <div className="assessments-section">
          <h2 id="Assessments">Medical Assessments</h2>
          <div className="assessment-filters">
            <input
              type="text"
              placeholder="Search assessments..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-bar"
            />
            <div className="category-filters">
              <button 
                className={`category-button ${selectedCategory === 'all' ? 'active' : ''}`}
                onClick={() => setSelectedCategory('all')}
              >
                All
              </button>
              {categories.map(category => (
                <button 
                  key={category}
                  className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
          
          {loading ? (
            <div className="loading-spinner">Loading assessments...</div>
          ) : error ? (
            <div className="error-message">Error loading assessments: {error}</div>
          ) : (
            <div className="assessments-grid">
              {filteredAssessments.length > 0 ? (
                filteredAssessments.map((assessment) => (
                  <div key={assessment.id} className="assessment-card">
                    <div className="assessment-card-inner">
                      <img 
                        src={assessment.image || "https://via.placeholder.com/150"} 
                        alt={assessment.name} 
                        className="assessment-image" 
                      />
                      <div className="assessment-content">
                        <h3 className="assessment-title">{assessment.name}</h3>
                        <p className="assessment-description">{assessment.description || "Test your knowledge with this adaptive assessment."}</p>
                        <div className="assessment-meta">
                          {assessment.CAT && (
                            <span className="assessment-badge">Adaptive</span>
                          )}
                          {assessment.isPaid ? (
                            <span className="assessment-badge premium">Premium</span>
                          ) : (
                            <span className="assessment-badge free">Free</span>
                          )}
                        </div>
                        {renderButton(assessment)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-results">No assessments match your search criteria.</div>
              )}
            </div>
          )}
        </div>
        
        <div className="testimonial-section">
          <h2>What Medical Students Say</h2>
          <div className="testimonial-cards">
            <div className="testimonial-card">
              <p>"The adaptive assessments helped me identify weak areas in my MCAT preparation that I hadn't realized before."</p>
              <div className="testimonial-author">- Medical Student, Johns Hopkins</div>
            </div>
            <div className="testimonial-card">
              <p>"I credit my NCLEX success to these adaptive quizzes. They're like having a personal tutor that knows exactly what you need to work on."</p>
              <div className="testimonial-author">- Nursing Graduate, UCLA</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="assessments-container" id="Assessments">
      {renderAssessmentContent()}
    </div>
  );
}

export default AdaptiveAssessments;
