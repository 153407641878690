import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import './abilitygraph.css'; // Import the new CSS file

const AbilityGraph = ({ abilityScore }) => {
    console.log('Ability score in AbilityGraph:', abilityScore);
    
    // State for animation and interactive elements
    const [animate, setAnimate] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    
    // Simulate history data for demonstration purposes
    useEffect(() => {
        // Generate random history data points that trend toward the current score
        const generateHistoryPoints = () => {
            const points = [];
            let currentValue = Math.random() * 2 - 1; // Start with random value between -1 and 1
            
            for (let i = 0; i < 10; i++) {
                // Trend toward the actual ability score
                currentValue = currentValue * 0.7 + abilityScore * 0.3 + (Math.random() * 0.4 - 0.2);
                points.push({
                    x: i,
                    y: currentValue,
                    size: 10 + Math.random() * 5
                });
            }
            
            // Add current score as the final point
            points.push({
                x: 10,
                y: abilityScore,
                size: 14
            });
            
            return points;
        };
        
        setHistoryData(generateHistoryPoints());
        
        // Trigger animation after component mounts
        setTimeout(() => setAnimate(true), 500);
    }, [abilityScore]);

    // Function to generate Ada's dynamic messages based on the ability score
    const getAdaAnnotation = () => {
        if (abilityScore > 2) {
            return 'Exceptional! Your understanding is at an advanced level.';
        } else if (abilityScore > 1) {
            return 'Impressive progress! You\'re developing strong competency.';
        } else if (abilityScore > 0) {
            return 'Good work! Your skills are growing steadily.';
        } else if (abilityScore > -1) {
            return 'You\'re on the right track. Keep practicing to improve!';
        } else if (abilityScore > -2) {
            return 'This concept needs more attention. Let\'s work on it together.';
        } else {
            return 'Let\'s focus on building your foundational knowledge in this area.';
        }
    };

    // Get appropriate color based on ability score
    const getScoreColor = () => {
        if (abilityScore > 1.5) return '#3eb489'; // Mint green for excellence
        if (abilityScore > 0.5) return '#4c9aff'; // Blue for above average
        if (abilityScore > -0.5) return '#7f7fff'; // Periwinkle for average
        if (abilityScore > -1.5) return '#d18237'; // Amber for below average
        return '#c85450'; // Rust red for needs improvement
    };

    // Rounded ability score
    const roundedAbilityScore = abilityScore.toFixed(2);

    // History trend line data
    const trendData = {
        x: historyData.map(point => point.x),
        y: historyData.map(point => point.y),
        type: 'scatter',
        mode: 'lines+markers',
        name: 'Progress',
        line: {
            color: '#4d79ff',
            width: 3,
            shape: 'spline',
            smoothing: 1.3,
            dash: 'solid'
        },
        marker: {
            color: historyData.map(() => '#4d79ff'),
            size: historyData.map(point => point.size),
            opacity: 0.7,
            line: {
                color: '#ffffff',
                width: 1
            }
        },
        hoverinfo: 'y',
        hoverlabel: {
            bgcolor: '#1a1a2e',
            bordercolor: '#00d4ff',
            font: { family: 'Roboto Mono', size: 12, color: 'white' }
        }
    };

    // Current score marker
    const currentScoreData = {
        x: [abilityScore],
        y: [0],
        type: 'scatter',
        mode: 'markers',
        name: 'Current Ability',
        marker: {
            color: getScoreColor(),
            size: animate ? 20 : 14,
            symbol: 'diamond',
            line: {
                color: '#ffffff',
                width: 2
            }
        },
        hoverinfo: 'x',
        hoverlabel: {
            bgcolor: '#1a1a2e',
            bordercolor: getScoreColor(),
            font: { family: 'Roboto Mono', size: 14 }
        }
    };

    // Reference areas to visualize score ranges
    const rangeAreas = [
        // Expert level
        {
            type: 'rect',
            x0: 2,
            x1: 3,
            y0: -1,
            y1: 1,
            fillcolor: 'rgba(62, 180, 137, 0.08)',
            line: { width: 0 }
        },
        // Proficient level
        {
            type: 'rect',
            x0: 1,
            x1: 2,
            y0: -1,
            y1: 1,
            fillcolor: 'rgba(76, 154, 255, 0.08)',
            line: { width: 0 }
        },
        // Average level
        {
            type: 'rect',
            x0: -1,
            x1: 1,
            y0: -1,
            y1: 1,
            fillcolor: 'rgba(127, 127, 255, 0.08)',
            line: { width: 0 }
        },
        // Developing level
        {
            type: 'rect',
            x0: -2,
            x1: -1,
            y0: -1,
            y1: 1,
            fillcolor: 'rgba(209, 130, 55, 0.08)',
            line: { width: 0 }
        },
        // Beginner level
        {
            type: 'rect',
            x0: -3,
            x1: -2,
            y0: -1,
            y1: 1,
            fillcolor: 'rgba(200, 84, 80, 0.08)',
            line: { width: 0 }
        }
    ];

    // Label annotations for ability levels - remove from plot and use the footer indicators instead
    const levelAnnotations = [
        // Main annotation with Ada's message only
        {
            x: abilityScore,
            y: 0.7,
            xref: 'x',
            yref: 'y',
            text: getAdaAnnotation(),
            showarrow: true,
            arrowhead: 3,
            arrowsize: 1,
            arrowwidth: 1.5,
            arrowcolor: getScoreColor(),
            ax: 0,
            ay: -60,
            bgcolor: 'rgba(26, 26, 46, 0.7)',
            bordercolor: getScoreColor(),
            borderwidth: 1,
            borderpad: 4,
            font: {
                color: '#ffffff',
                family: 'Roboto',
                size: 12
            }
        }
    ];

    const layout = {
        title: {
            text: 'Ability Assessment',
            font: {
                family: 'Roboto, sans-serif',
                size: 24,
                color: '#4c9aff'
            }
        },
        xaxis: {
            title: 'Ability Score Range',
            titlefont: {
                family: 'Roboto, sans-serif',
                size: 16,
                color: '#4c9aff'
            },
            showgrid: true,
            zeroline: false,
            autotick: false,
            dtick: 1,
            range: [-3, 3],
            gridcolor: 'rgba(255, 255, 255, 0.08)',
            gridwidth: 1,
            tickfont: {
                family: 'Roboto',
                size: 12,
                color: '#8f9cb3'
            },
            // Add axis labels to show the range areas
            tickvals: [-3, -2, -1, 0, 1, 2, 3],
            ticktext: ['-3', '-2', '-1', '0', '1', '2', '3']
        },
        yaxis: {
            showticklabels: false,
            showgrid: false,
            zeroline: false,
            range: [-1, 1]
        },
        shapes: [
            // Horizontal center line
            {
                type: 'line',
                x0: -3,
                y0: 0,
                x1: 3,
                y1: 0,
                line: {
                    color: 'rgba(255, 255, 255, 0.3)',
                    width: 2,
                    dash: 'dot'
                }
            },
            // Vertical line at current ability score
            {
                type: 'line',
                x0: abilityScore,
                y0: -1,
                x1: abilityScore,
                y1: 1,
                line: {
                    color: getScoreColor(),
                    width: 2,
                    dash: 'solid'
                }
            },
            // Range area shapes
            ...rangeAreas
        ],
        annotations: levelAnnotations,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        margin: {
            l: 50,
            r: 50,
            b: 70,
            t: 100,
            pad: 10
        },
        showlegend: false,
        hovermode: 'closest',
        dragmode: false,
        transition: {
            duration: 800,
            easing: 'cubic-in-out'
        }
    };

    const config = {
        responsive: true,
        displayModeBar: false
    };

    return (
        <div className="graphContainer">
            <div className="graph-header">
                <h5 className="app-title">Cognitive Assessment</h5>
                <div className={`digital-display ${animate ? 'animated' : ''}`}>
                    <span className="digital-label">ABILITY SCORE</span>
                    <span className="digital-score" style={{ color: getScoreColor() }}>
                        {roundedAbilityScore}
                    </span>
                </div>
            </div>
            
            <div className="plotly-container">
                <Plot 
                    data={[trendData, currentScoreData]} 
                    layout={layout} 
                    config={config}
                    useResizeHandler={true} 
                    style={{ width: "100%", height: "350px" }} 
                />
            </div>
            
            <div className="graph-footer">
                <div className="skill-scale">
                    <div className="scale-line"></div>
                    <div className="scale-labels">
                        <div className="scale-marker" style={{ left: '0%' }}>
                            <span className="scale-value">-3</span>
                            <span className="scale-label">Beginner</span>
                        </div>
                        <div className="scale-marker" style={{ left: '16.7%' }}>
                            <span className="scale-value">-2</span>
                        </div>
                        <div className="scale-marker" style={{ left: '33.3%' }}>
                            <span className="scale-value">-1</span>
                            <span className="scale-label">Basic</span>
                        </div>
                        <div className="scale-marker" style={{ left: '50%' }}>
                            <span className="scale-value">0</span>
                            <span className="scale-label">Developing</span>
                        </div>
                        <div className="scale-marker" style={{ left: '66.7%' }}>
                            <span className="scale-value">1</span>
                            <span className="scale-label">Proficient</span>
                        </div>
                        <div className="scale-marker" style={{ left: '83.3%' }}>
                            <span className="scale-value">2</span>
                            <span className="scale-label">Expert</span>
                        </div>
                        <div className="scale-marker" style={{ left: '100%' }}>
                            <span className="scale-value">3</span>
                        </div>
                    </div>
                </div>
                <div className="skill-indicators">
                    <div className="skill-level">
                        <span className="skill-dot" style={{background: 'rgba(200, 84, 80, 0.8)'}}></span>
                        <span>Beginner</span>
                    </div>
                    <div className="skill-level">
                        <span className="skill-dot" style={{background: 'rgba(209, 130, 55, 0.8)'}}></span>
                        <span>Basic</span>
                    </div>
                    <div className="skill-level">
                        <span className="skill-dot" style={{background: 'rgba(127, 127, 255, 0.8)'}}></span>
                        <span>Developing</span>
                    </div>
                    <div className="skill-level">
                        <span className="skill-dot" style={{background: 'rgba(76, 154, 255, 0.8)'}}></span>
                        <span>Proficient</span>
                    </div>
                    <div className="skill-level">
                        <span className="skill-dot" style={{background: 'rgba(62, 180, 137, 0.8)'}}></span>
                        <span>Expert</span>
                    </div>
                </div>
                
                <div className="ada-message">
                    <div className="ada-icon">AI</div>
                    <p>{getAdaAnnotation()}</p>
                </div>
                
                <div className="interpretation-note">
                    <p>This visualization represents your current ability level based on assessment performance.</p>
                </div>
            </div>
        </div>
    );
};

export default AbilityGraph;
