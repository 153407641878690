import React, { useEffect, useRef, useState } from "react";

const MarvinJSEditor = () => {
  const marvinContainerRef = useRef(null);
  const [fontSize, setFontSize] = useState(14); // Default font size
  const sketcherInstanceRef = useRef(null); // Store MarvinJS instance

  useEffect(() => {
    const loadMarvinJS = async () => {
      try {
        if (!window.MarvinJSUtil) {
          console.error("MarvinJSUtil is not available. Check your script and folder setup.");
          return;
        }

        const sketcherInstance = await window.MarvinJSUtil.getEditor(marvinContainerRef.current);
        sketcherInstanceRef.current = sketcherInstance; // Store instance for later use

        console.log("Marvin JS Editor initialized:", sketcherInstance);

        // Apply initial font size settings
        sketcherInstance.setDisplaySettings({
          "atomLabelFontSize": fontSize,
          "reactionPlusFontSize": 12,
          "atomLabelFont": "Arial",
        });

        // Set example molecule
        sketcherInstance.setMol("C1=CC=CC=C1"); // Benzene
      } catch (err) {
        console.error("Error initializing Marvin JS:", err);
      }
    };

    // Load scripts dynamically
    const loadScripts = () => {
      const script1 = document.createElement("script");
      script1.src = "/Marvinjs/js/lib/jquery-3.6.0.min.js";
      script1.async = true;

      const script2 = document.createElement("script");
      script2.src = "/Marvinjs/gui/lib/promise-1.0.0.min.js";
      script2.async = true;

      const script3 = document.createElement("script");
      script3.src = "/Marvinjs/js/marvinjslauncher.js";
      script3.async = true;
      script3.onload = loadMarvinJS;

      document.body.appendChild(script1);
      document.body.appendChild(script2);
      document.body.appendChild(script3);

      return () => {
        document.body.removeChild(script1);
        document.body.removeChild(script2);
        document.body.removeChild(script3);
      };
    };

    const cleanupScripts = loadScripts();

    return () => {
      cleanupScripts();
    };
  }, []);

  // Function to update font size dynamically
  const updateFontSize = (size) => {
    setFontSize(size); // Update state
    if (sketcherInstanceRef.current) {
      sketcherInstanceRef.current.setDisplaySettings({
        "atomLabelFontSize": size,
        "reactionPlusFontSize": 12,
        "atomLabelFont": "Arial",
      });
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      {/* Instructional Header */}
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h2 style={{ color: "#007ACC" }}>Chemistry Editor</h2>
        <p style={{ fontSize: "16px", lineHeight: "1.5", maxWidth: "600px", margin: "0 auto" }}>
          This chemical editor allows you to draw and explore <strong>organic chemistry mechanisms</strong> 
          and <strong>molecular structures</strong> interactively. Use it to deepen your understanding of 
          complex chemical processes and visualize molecular interactions.
        </p>
        <p style={{ fontSize: "12px", fontStyle: "italic", marginTop: "10px", color: "#555" }}>
          (Editor provided by MarvinJS at ChemAxon)
        </p>
      </div>

      {/* Font Size Selector */}
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <label htmlFor="fontSizeSelector" style={{ fontSize: "16px", marginRight: "10px" }}>
          Atom Label Font Size:
        </label>
        <select
          id="fontSizeSelector"
          value={fontSize}
          onChange={(e) => updateFontSize(parseInt(e.target.value, 10))}
          style={{ padding: "5px", fontSize: "14px" }}
        >
          <option value="10">10px (Small)</option>
          <option value="12">12px</option>
          <option value="14">14px (Default)</option>
          <option value="16">16px</option>
          <option value="18">18px</option>
          <option value="20">20px (Large)</option>
        </select>
      </div>

      {/* Marvin JS Editor iframe */}
      <div className="resizable">
        <iframe
          ref={marvinContainerRef}
          src="/Marvinjs/demo.html"
          id="sketch"
          className="sketcher-frame"
          title="Marvin JS Editor"
          style={{
            width: "100%",
            height: "500px",
            border: "1px solid #ddd",
            borderRadius: "8px",
          }}
        ></iframe>
        <br/>
<br/>
<br/>
      </div>
    </div>
  );
};

export default MarvinJSEditor;
