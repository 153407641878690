import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './Chat.css';

const PrivateChat = () => {
    const userInfo = useSelector(state => state.userLogin.userInfo);
    const [friends, setFriends] = useState([]);
    const [friendRequests, setFriendRequests] = useState([]);
    const [outgoingRequests, setOutgoingRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeChat, setActiveChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const [onlineUsers, setOnlineUsers] = useState({});
    const socketRef = useRef(null);
    const messagesEndRef = useRef(null);

    // Fetch friends, friend requests, and outgoing requests
    useEffect(() => {
        if (!userInfo) return;

        const fetchFriendsAndProfiles = async () => {
            try {
                setLoading(true);
                const { data } = await axios.get('/api/friends/', {
                    headers: { Authorization: `Bearer ${userInfo.token}` }
                });
                setFriends(data.friends || []);
                setFriendRequests(data.friend_requests || []);
                setOutgoingRequests(data.outgoing_requests || []);
                setLoading(false);
            } catch (err) {
                setError('Failed to load friends');
                setLoading(false);
            }
        };

        fetchFriendsAndProfiles();
    }, [userInfo]);

    // Connect to user status WebSocket when component mounts
    useEffect(() => {
        if (!userInfo) return;

        const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const wsUrl = `${wsProtocol}//${window.location.host}/ws/user-status/`;
        
        const statusSocket = new WebSocket(wsUrl);
        
        statusSocket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (data.type === 'status_update') {
                setOnlineUsers(prev => ({
                    ...prev,
                    [data.user_id]: data.status === 'online'
                }));
            }
        };

        return () => {
            if (statusSocket.readyState === 1) { // OPEN
                statusSocket.close();
            }
        };
    }, [userInfo]);

    // Connect to private chat WebSocket when active chat changes
    useEffect(() => {
        console.log('Active Chat:', activeChat);
        if (!userInfo || !activeChat) return;

        const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const userId = userInfo.id;
        const friendId = activeChat;
        
        // Always sort user IDs to ensure consistent room naming
        const [user1Id, user2Id] = [userId, friendId].sort((a, b) => a - b);
        const wsUrl = `${wsProtocol}//${window.location.host}/wschat/private/${user1Id}/${user2Id}/`;
        
        console.log('Connecting to WebSocket:', wsUrl);
        socketRef.current = new WebSocket(wsUrl);
        
        socketRef.current.onopen = () => {
            console.log('WebSocket connected');
        };
        
        socketRef.current.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (data.type === 'chat_message') {
                setMessages(prev => [...prev, data.message]);
            } else if (data.type === 'chat_history') {
                setMessages(data.messages);
            }
        };
        
        socketRef.current.onerror = (e) => {
            console.error('WebSocket error:', e);
        };
        
        socketRef.current.onclose = () => {
            console.log('WebSocket disconnected');
        };
        
        return () => {
            if (socketRef.current && socketRef.current.readyState === 1) { // OPEN
                socketRef.current.close();
            }
        };
    }, [userInfo, activeChat]);

    // Auto-scroll to bottom when messages change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        
        if (!messageInput.trim() || !activeChat || !socketRef.current) return;
        
        const message = {
            sender_id: userInfo.id,
            receiver_id: activeChat,
            body: messageInput,
            timestamp: new Date().toISOString(),
        };
        
        socketRef.current.send(JSON.stringify({
            type: 'chat_message',
            message
        }));
        
        setMessageInput('');
    };

    const handleAcceptRequest = async (requesterId) => {
        try {
            await axios.post(`/api/friends/accept/${requesterId}/`, {}, {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            });
            
            // Update state to reflect the accepted request
            const acceptedRequest = friendRequests.find(req => req.id === requesterId);
            setFriends(prev => [...prev, acceptedRequest]);
            setFriendRequests(prev => prev.filter(req => req.id !== requesterId));
        } catch (err) {
            setError('Failed to accept friend request');
        }
    };

    const handleRejectRequest = async (requesterId) => {
        try {
            await axios.post(`/api/friends/reject/${requesterId}/`, {}, {
                headers: { Authorization: `Bearer ${userInfo.token}` }
            });
            
            // Update state to reflect the rejected request
            setFriendRequests(prev => prev.filter(req => req.id !== requesterId));
        } catch (err) {
            setError('Failed to reject friend request');
        }
    };

    if (!userInfo) {
        return (
            <div className="no-access">
                <h2>Please log in to access the chat</h2>
                <p>You need to be logged in to view your friends and chat with them.</p>
            </div>
        );
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="friends-chat-container">
            <div className="friends-column">
                <h3>Friends</h3>
                {friends.length === 0 ? (
                    <p>No friends yet. Add some friends to start chatting!</p>
                ) : (
                    friends.map(friend => (
                        <div 
                            key={friend.id}
                            className={`friend-item ${activeChat === friend.id ? 'active' : ''}`}
                            onClick={() => setActiveChat(friend.id)}
                        >
                            <div className="friend-avatar">
                                {friend.profile && friend.profile.profile_picture ? (
                                    <img src={friend.profile.profile_picture} alt={friend.username} />
                                ) : (
                                    <div>{friend.username.charAt(0).toUpperCase()}</div>
                                )}
                            </div>
                            <div className="friend-details">
                                <span className="friend-name">{friend.username}</span>
                                <span className="online-status">
                                    <span className={`status-dot ${onlineUsers[friend.id] ? 'online' : 'offline'}`}></span>
                                    {onlineUsers[friend.id] ? 'Online' : 'Offline'}
                                </span>
                            </div>
                        </div>
                    ))
                )}

                {friendRequests.length > 0 && (
                    <>
                        <h4>Friend Requests</h4>
                        {friendRequests.map(request => (
                            <div key={request.id} className="friend-item">
                                <div className="friend-avatar">
                                    {request.profile && request.profile.profile_picture ? (
                                        <img src={request.profile.profile_picture} alt={request.username} />
                                    ) : (
                                        <div>{request.username.charAt(0).toUpperCase()}</div>
                                    )}
                                </div>
                                <div className="friend-details">
                                    <span className="friend-name">{request.username}</span>
                                    <div>
                                        <button onClick={() => handleAcceptRequest(request.id)}>Accept</button>
                                        <button onClick={() => handleRejectRequest(request.id)}>Reject</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}

                {outgoingRequests.length > 0 && (
                    <>
                        <h4>Outgoing Requests</h4>
                        {outgoingRequests.map(request => (
                            <div key={request.id} className="friend-item">
                                <div className="friend-avatar">
                                    {request.profile && request.profile.profile_picture ? (
                                        <img src={request.profile.profile_picture} alt={request.username} />
                                    ) : (
                                        <div>{request.username.charAt(0).toUpperCase()}</div>
                                    )}
                                </div>
                                <div className="friend-details">
                                    <span className="friend-name">{request.username}</span>
                                    <span>Pending</span>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>

            <div className="chat-column">
                {activeChat ? (
                    <>
                        <div className="chat-active-header">
                            {(() => {
                                const friend = friends.find(f => f.id === activeChat);
                                return (
                                    <>
                                        <div className="friend-avatar">
                                            {friend.profile && friend.profile.profile_picture ? (
                                                <img src={friend.profile.profile_picture} alt={friend.username} />
                                            ) : (
                                                <div>{friend.username.charAt(0).toUpperCase()}</div>
                                            )}
                                        </div>
                                        <div className="friend-details">
                                            <span className="friend-name">{friend.username}</span>
                                            <span className="online-status">
                                                <span className={`status-dot ${onlineUsers[friend.id] ? 'online' : 'offline'}`}></span>
                                                {onlineUsers[friend.id] ? 'Online' : 'Offline'}
                                            </span>
                                        </div>
                                    </>
                                );
                            })()}
                        </div>
                        
                        <div className="messages">
                            {messages.length === 0 ? (
                                <div className="empty-chat">
                                    <p>No messages yet. Start the conversation!</p>
                                </div>
                            ) : (
                                messages.map((message, index) => (
                                    <div 
                                        key={index}
                                        className={`message ${message.sender_id === userInfo.id ? 'sent' : 'received'}`}
                                    >
                                        <div className="message-bubble">
                                            <div className="message-text">{message.body}</div>
                                            <div className="message-time">
                                                {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                            <div ref={messagesEndRef} />
                        </div>
                        
                        <form className="message-input" onSubmit={handleSendMessage}>
                            <textarea
                                value={messageInput}
                                onChange={(e) => setMessageInput(e.target.value)}
                                placeholder="Type a message..."
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage(e);
                                    }
                                }}
                            />
                            <button type="submit">Send</button>
                        </form>
                    </>
                ) : (
                    <div className="chat-placeholder">
                        <h3>Select a friend to start chatting</h3>
                        <p>Choose a friend from the list to begin a conversation</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PrivateChat; 