import React, { useState } from 'react';
import './InteractiveLabs.css'; // Importing the CSS file
import useSound from '../Sound';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function InteractiveLabs() {
    const { play, stop, setLoop } = useSound('/ui-click-43196.mp3');
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const navigate = useNavigate(); // Hook to programmatically navigate
    const [activeFilter, setActiveFilter] = useState('all');

    const handleAccess = (path) => {
        if (userInfo) {
            play();
            navigate(path);
        } else {
            alert("Please login to access these interactive medical learning tools. They're all free!");
            navigate("/Login");
        }
    };

    // Categorized lab applications with medical relevance details
    const labCategories = [
        {
            id: 'anatomy',
            name: 'Anatomy & Physiology',
            description: 'Explore 3D models and interactive visualizations of human anatomy',
            icon: '🧠',
            apps: [
                {
                    id: 'anatomy-lab',
                    title: 'Anatomy Lab',
                    description: 'Interactive 3D anatomy models with detailed structure labeling',
                    image: 'image/SKELETONIMAGE.png',
                    path: '/anatomy2',
                    relevance: 'Essential for medical students to understand anatomical structures and relationships'
                }
            ]
        },
        {
            id: 'biochemistry',
            name: 'Biochemistry & Molecular Biology',
            description: 'Visualize complex biomolecules and biochemical processes',
            icon: '🧬',
            apps: [
                {
                    id: 'molecule-viewer',
                    title: 'Macromolecule Visualizer',
                    description: 'Interactive 3D visualization of proteins, nucleic acids, and other biomolecules',
                    image: 'image/protein.png',
                    path: '/MoleculeViewer',
                    relevance: 'Critical for understanding protein structure-function relationships in medicine'
                },
                {
                    id: 'hemoglobin-states',
                    title: 'Hemoglobin T/R States',
                    description: 'Visualize hemoglobin conformational changes and oxygen binding',
                    image: 'image/hemoglobin2.png',
                    path: '/localmoleculeviewer',
                    relevance: 'Important for understanding oxygen transport, hemoglobinopathies, and respiratory physiology'
                }
            ]
        },
        {
            id: 'chemistry',
            name: 'Medical Chemistry',
            description: 'Foundational chemical principles relevant to medicine and pharmacology',
            icon: '⚗️',
            apps: [
                {
                    id: 'molecular-editor',
                    title: 'Molecular Structure Editor',
                    description: 'Draw and manipulate molecular structures of drugs and biomolecules',
                    image: 'image/chemdrawing.png',
                    path: '/chemstuff',
                    relevance: 'Useful for understanding drug structures, functional groups, and pharmacophores'
                },
                {
                    id: 'periodic-table',
                    title: 'Interactive Periodic Table',
                    description: 'Explore atomic structures and properties relevant to biological systems',
                    image: 'image/magnesiumpic.png',
                    path: '/atom',
                    relevance: 'Helps understand electrolyte chemistry, trace elements, and diagnostic imaging principles'
                }
            ]
        },
        {
            id: 'physics',
            name: 'Medical Physics',
            description: 'Physical principles applied to medical diagnostics and therapeutics',
            icon: '📊',
            apps: [
                {
                    id: 'wave-interference',
                    title: 'Wave Interference Simulator',
                    description: 'Visualize wave properties essential for understanding imaging technologies',
                    image: 'image/wave2pic.png',
                    path: '/Physicslab5',
                    relevance: 'Important for understanding ultrasound, MRI, and radiation-based diagnostics'
                },
                {
                    id: 'momentum-sim',
                    title: 'Momentum & Collision Physics',
                    description: 'Simulate momentum conservation relevant to trauma biomechanics',
                    image: 'image/collision.png',
                    path: '/Physicslab2',
                    relevance: 'Helps understand trauma mechanisms and injury patterns'
                }
            ]
        },
        {
            id: 'data',
            name: 'Medical Data Analysis',
            description: 'Statistical and computational methods for analyzing medical data',
            icon: '📈',
            apps: [
                {
                    id: 'regression-analysis',
                    title: 'Linear Regression Analysis',
                    description: 'Interactive tool for analyzing relationships in medical data',
                    image: 'image/Linearregression.png',
                    path: '/ML',
                    relevance: 'Essential for understanding clinical research, epidemiology, and evidence-based medicine'
                },
                {
                    id: 'function-grapher',
                    title: 'Function Graphing Tool',
                    description: 'Visualize mathematical functions for modeling physiological processes',
                    image: 'image/visual_math_functions.png',
                    path: '/Algebralab3',
                    relevance: 'Useful for understanding pharmacokinetics, growth curves, and physiological models'
                }
            ]
        }
    ];

    const filteredCategories = activeFilter === 'all' 
        ? labCategories 
        : labCategories.filter(category => category.id === activeFilter);

    return (
        <div className="med-labs-container">
            <div className="med-labs-header">
                <h1 className="med-labs-title">Interactive Medical Learning Tools</h1>
                <p className="med-labs-subtitle">
                    Enhance your medical education with these interactive applications designed 
                    to help visualize complex concepts and improve comprehension of key medical topics.
                </p>
                
                <div className="med-labs-filters">
                    <button 
                        className={`filter-button ${activeFilter === 'all' ? 'active' : ''}`}
                        onClick={() => setActiveFilter('all')}
                    >
                        All Tools
                    </button>
                    {labCategories.map(category => (
                        <button 
                            key={category.id}
                            className={`filter-button ${activeFilter === category.id ? 'active' : ''}`}
                            onClick={() => setActiveFilter(category.id)}
                        >
                            {category.icon} {category.name}
                        </button>
                    ))}
                </div>
            </div>

            <div className="med-labs-content">
                {filteredCategories.map(category => (
                    <div className="med-lab-category" key={category.id}>
                        <div className="category-header">
                            <h2>{category.icon} {category.name}</h2>
                            <p>{category.description}</p>
                        </div>
                        
                        <div className="med-apps-grid">
                            {category.apps.map(app => (
                                <div className="med-app-card" key={app.id}>
                                    <div className="med-app-image">
                                        <img src={app.image} alt={app.title} />
                                    </div>
                                    <div className="med-app-content">
                                        <h3>{app.title}</h3>
                                        <p className="med-app-description">{app.description}</p>
                                        <div className="med-app-relevance">
                                            <span className="relevance-label">Medical Relevance:</span>
                                            <p>{app.relevance}</p>
                                        </div>
                                        <button 
                                            className="med-app-button"
                                            onClick={() => handleAccess(app.path)}
                                        >
                                            Launch Application
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default InteractiveLabs;