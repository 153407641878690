import React from 'react';
import './MathKeyboard.css'; // Enhanced CSS file

const MathKeyboard = ({ onKeyPress, theme = 'light' }) => {
    // Expanded keyboard with more mathematical operators
    const keyboardLayout = [
        [
            { label: '7', value: '7' },
            { label: '8', value: '8' },
            { label: '9', value: '9' },
            { label: '÷', value: '/' },
            { label: 'DEL', value: '<<', className: 'key-del' }
        ],
        [
            { label: '4', value: '4' },
            { label: '5', value: '5' },
            { label: '6', value: '6' },
            { label: '×', value: '*' },
            { label: '(', value: '(' }
        ],
        [
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '−', value: '-' },
            { label: ')', value: ')' }
        ],
        [
            { label: '0', value: '0' },
            { label: '.', value: '.' },
            { label: 'π', value: 'pi' },
            { label: '+', value: '+' },
            { label: '^', value: '^' }
        ],
        [
            { label: 'sin', value: 'sin(' },
            { label: 'cos', value: 'cos(' },
            { label: 'tan', value: 'tan(' },
            { label: 'log', value: 'log(' },
            { label: 'ln', value: 'ln(' }
        ],
        [
            { label: '√', value: 'sqrt(' },
            { label: 'x', value: 'x' },
            { label: 'e', value: 'e' },
            { label: '|x|', value: 'abs(' },
            { label: '!', value: '!' }
        ]
    ];

    return (
        <div className={`math-keyboard ${theme}`}>
            {keyboardLayout.map((row, rowIndex) => (
                <div key={rowIndex} className="keyboard-row">
                    {row.map((key, keyIndex) => (
                        <button
                            key={`${rowIndex}-${keyIndex}`}
                            className={`key ${key.className || ''}`}
                            onClick={() => onKeyPress(key.value)}
                            title={key.title || key.label}
                        >
                            {key.label}
                        </button>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default MathKeyboard;
