import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import '../Chat2.css';

const Chatappembedded = forwardRef(({ initialMessages = [], isMiniView = false }, ref) => {
  const [messages, setMessages] = useState(initialMessages);
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [speech, speechToggle] = useState(true);
  const access_token = useSelector((state) => state.userLogin.userInfo?.access);
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const username = userInfo ? userInfo.username : 'Anonymous';
  const botImage = 'https://andrewslearningimage-bucket.s3.amazonaws.com/Ada.png';

  const messagesEndRef = useRef(null); // Ref to scroll to the bottom

  // Scroll to the bottom of messages
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Sync initialMessages from parent
  useEffect(() => {
    setMessages(initialMessages); // Changed from messages to initialMessages to properly sync with parent state
  }, [initialMessages]);

  useEffect(() => {
    if (!access_token || socket) return;

    const ws = new WebSocket('wss://api.andrewslearning.com/wschat/gptchatroom3/');
    setSocket(ws);

    ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      setLoading(false); // Stop loading when a response is received
      const data = JSON.parse(event.data);
      const newMessage = { text: data.message, sent: false, author: data.author || 'Server' };
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      // Trigger text-to-speech
   {/*  if (speech==true) {
      speakMessage(data.message);
    }*/} 
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed', event);
      setSocket(null); // Clear socket on close
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [access_token, username, socket]);

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when messages update
  }, [messages]);

  // Text-to-Speech function
  const speakMessage = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'en-US'; // Set language
      utterance.rate = 1; // Adjust speech rate (0.1 to 10)
      utterance.pitch = 1; // Adjust pitch (0 to 2)
      window.speechSynthesis.speak(utterance);
    } else {
      console.error('Text-to-Speech is not supported in this browser.');
    }
  };



  // Method to send a message
  const sendMessage = async (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      setLoading(true); // Start loading when sending a message
      socket.send(JSON.stringify({ message, author: username }));
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: message, sent: true, author: username },
      ]);
    } else {
      console.error('WebSocket is not connected or open.');
    }
  };

  // Expose sendMessage to parent via ref
  useImperativeHandle(ref, () => ({
    sendMessage,
  }));

  if (!access_token) {
    return (
      <div className="chat-container">
        <div className="no-access">
          <h2>Access Denied</h2>
          <p>Please log in and try again.</p>
        </div>
      </div>
    );
  }

  // Filter only received messages (not sent)
  const receivedMessages = messages.filter((msg) => !msg.sent);

  return (
    <div className={`chat-container ${isMiniView ? 'mini-chat' : ''}`}>
      <div className="chat-header">Ada Says: 
      <img src={botImage} alt="Bot Avatar" className="bot-avatar" />
</div>
      <div className="chat-messages">
        {receivedMessages.map((msg, index) => (
          <div key={index} className="message-wrapper">
            <img src={botImage} alt="Bot Avatar" className="bot-avatar" />
            <div className="message-bubble">{msg.text}</div>
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Empty div to scroll into view */}
      </div>
      <br></br>
      <br></br>
    </div>
  );
});

Chatappembedded.propTypes = {
  initialMessages: PropTypes.array,
  isMiniView: PropTypes.bool,
};

export default Chatappembedded;
