import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Videolist.css';

const VideoList = () => {
  const [lectures, setLectures] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');

  useEffect(() => {
    setLoading(true);
    fetch('https://api.andrewslearning.com/api/all_lectures')
      .then(response => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      })
      .then(data => {
        setLectures(data);
        
        // Extract unique categories from lectures
        const uniqueCategories = [...new Set(data.map(lecture => 
          lecture.category || 'Uncategorized'
        ))];
        setCategories(uniqueCategories);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error retrieving lectures:', err);
        setError(err.toString());
        setLoading(false);
      });
  }, []);

  const placeholderImage = "https://via.placeholder.com/240x135/eee/999?text=Video";
  
  // Filter lectures by search and category
  const filteredLectures = lectures.filter(lecture => {
    const matchesSearch = lecture.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || 
                           (lecture.category || 'Uncategorized') === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="video-library-container">
      <div className="video-library-header">
        <h1 className="video-library-title">Video Library</h1>
        <p className="video-library-subtitle">
          Explore our collection of {lectures.length} educational videos
        </p>
      </div>
      
      <div className="video-library-controls">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search videos..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-bar"
          />
        </div>
        
        <div className="category-filter">
          <select 
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="category-select"
          >
            <option value="all">All Categories</option>
            {categories.map(category => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      {loading ? (
        <div className="video-list-loading">
          <div className="loading-spinner"></div>
          <p>Loading videos...</p>
        </div>
      ) : error ? (
        <div className="video-list-error">
          <p>Error loading videos: {error}</p>
        </div>
      ) : (
        <>
          <div className="video-results-info">
            Showing {filteredLectures.length} of {lectures.length} videos
            {searchQuery && <span> matching "{searchQuery}"</span>}
            {selectedCategory !== 'all' && <span> in {selectedCategory}</span>}
          </div>
          
          <div className="videos-grid">
            {filteredLectures.length > 0 ? filteredLectures.map((lecture) => (
              <Link key={lecture.id} to={`/video/${lecture.id}`} className="video-card-link">
                <div className="video-card">
                  <div className="video-thumbnail-container">
                    <img 
                      src={lecture.videoimage || placeholderImage} 
                      alt={lecture.title} 
                      className="video-image" 
                    />
                    <div className="video-play-button">
                      <svg viewBox="0 0 24 24" fill="currentColor">
                        <path d="M8 5v14l11-7z" />
                      </svg>
                    </div>
                  </div>
                  <div className="video-content">
                    <h3 className="video-title-list">{lecture.title}</h3>
                    {lecture.category && (
                      <span className="video-category">{lecture.category}</span>
                    )}
                    {lecture.date_created && (
                      <span className="video-date">{formatDate(lecture.date_created)}</span>
                    )}
                  </div>
                </div>
              </Link>
            )) : (
              <div className="no-videos-found">
                <p>No videos match your search criteria. Try adjusting your filters.</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VideoList;
