import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './FounderLetter.css';

const FounderLetter = () => {
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    // Set visible after component mounts for animation
    setIsVisible(true);

    // Add scroll event listener to check when elements come into view
    const handleScroll = () => {
      if (contentRef.current) {
        const rect = contentRef.current.getBoundingClientRect();
        const isInView = rect.top <= window.innerHeight && rect.bottom >= 0;
        if (isInView) {
          contentRef.current.classList.add('visible');
        }
      }
    };

    // Call once on mount to check initial state
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="founder-letter-container">
      {/* Header with Background Image */}
      <div className="founder-letter-header">
        <div className="founder-letter-overlay"></div>
        <h1>
          Letter from our <span className="highlight">Founder</span>
        </h1>
      </div>

      <Container>
        {/* Letter Content */}
        <div className="founder-letter-content" ref={contentRef}>
          <div className="founder-signature">
            <div className="line"></div>
            <h2>Our Vision for Education</h2>
            <div className="line"></div>
          </div>

          <div className="founder-text">
            <p className="greeting">Dear Students, Educators, and Knowledge Seekers,</p>

            <p>
              When I started this journey, I was driven by a simple yet powerful observation: 
              our educational system too often prioritizes rote memorization over genuine understanding. 
              As a student who struggled with the idea of memorizing material, never understanding it enough to retain it, and ultimately forgetting it overtime, I found myself developing alternative approaches 
              to learning complex subjects like organic chemistry and mathematics. What began as personal 
              strategies evolved into a vision for a different kind of learning platform.
            </p>

            <p>
              I believe that true education is not about remembering facts in isolation, but about 
              building mental models that allow us to see connections between concepts. It's about
              developing an intuition that makes complex ideas feel natural, not foreign. This is 
              why our platform focuses on interactive exploration and visualization rather than 
              flashcards and memorization drills.
            </p>

            <div className="scientific-method-box">
              <h3>The Scientific Method as a Learning Philosophy</h3>
              
              <div className="method-steps">
                <div className="method-step">
                  <div className="step-number">1</div>
                  <div className="step-content">
                    <h4>Observation</h4>
                    <p>Begin by carefully observing the subject matter. Notice patterns, structures, and behaviors without preconceptions.</p>
                  </div>
                </div>
                
                <div className="method-step">
                  <div className="step-number">2</div>
                  <div className="step-content">
                    <h4>Question Formation</h4>
                    <p>Develop specific questions about what you observe. Quality questions lead to quality learning.</p>
                  </div>
                </div>
                
                <div className="method-step">
                  <div className="step-number">3</div>
                  <div className="step-content">
                    <h4>Hypothesis Creation</h4>
                    <p>Form testable explanations that attempt to answer your questions. These are mental models you can evaluate.</p>
                  </div>
                </div>
                
                <div className="method-step">
                  <div className="step-number">4</div>
                  <div className="step-content">
                    <h4>Experimentation</h4>
                    <p>Test your understanding through practice problems, interactive simulations, and applications to new contexts.</p>
                  </div>
                </div>
                
                <div className="method-step">
                  <div className="step-number">5</div>
                  <div className="step-content">
                    <h4>Analysis</h4>
                    <p>Evaluate the results of your experiments. Did your understanding hold up? Where were the gaps?</p>
                  </div>
                </div>
                
                <div className="method-step">
                  <div className="step-number">6</div>
                  <div className="step-content">
                    <h4>Iteration</h4>
                    <p>Refine your mental models based on what you learned. Each iteration builds deeper understanding.</p>
                  </div>
                </div>
              </div>
            </div>

            <p>
              This scientific approach to learning is at the heart of our platform. Instead of
              presenting knowledge as a finished product to be memorized, we invite you to 
              engage with it as a scientist would—through active exploration, testing, and refinement.
              Our interactive labs, 3D models, and dynamic simulations are designed to facilitate 
              this process of discovery.
            </p>

            <p>
              We've seen remarkable results when students approach subjects like organic chemistry, 
              mathematics, and biology with this mindset. Concepts that once seemed impenetrable 
              become accessible. Relationships between ideas that were obscure become clear. 
              And perhaps most importantly, the anxiety that often accompanies memorization-heavy 
              learning diminishes, replaced by the joy of discovery and understanding.
            </p>

            <p>
              As we continue to develop this platform, our commitment remains unwavering: to create 
              educational experiences that honor how the human mind actually learns. We're dedicated 
              to building tools that transform abstract concepts into intuitive, manipulable models 
              that you can explore at your own pace and in your own way.
            </p>

            <p className="closing">
              Thank you for joining us on this journey of discovery. I'm excited to see how you'll 
              use these tools to transform your own understanding and, perhaps, contribute to the 
              advancement of knowledge in ways we've yet to imagine.
            </p>

            <div className="signature">
              <div className="signature-name">Andrew</div>
              <div className="founder-title">Founder & Educational Scientist</div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <Row className="founder-letter-cta">
          <Col md={8} className="mx-auto">
            <div className="cta-card">
              <h3>Ready to Experience Science Through Understanding?</h3>
              <p>Explore our interactive labs and self-assessment tools designed to build intuition, not just memorization.</p>
              
              <div className="cta-buttons">
                <Link to="/interactive-labs" className="cta-button primary">
                  Explore Interactive Labs
                </Link>
                <Link to="/assessments" className="cta-button secondary">
                  Try Self Assessments
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FounderLetter; 
