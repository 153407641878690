import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './BlogList.css';

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortBy, setSortBy] = useState('newest');
  const [featuredPost, setFeaturedPost] = useState(null);
  const navigate = useNavigate();
  const currentUser = useSelector(state => state.userLogin.userInfo);
  const headerRef = useRef(null);

  useEffect(() => {
    fetchPosts();
    
    // Add scroll animation for the header
    const handleScroll = () => {
      if (headerRef.current && window.scrollY > 100) {
        headerRef.current.classList.add('scrolled');
      } else if (headerRef.current) {
        headerRef.current.classList.remove('scrolled');
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const fetchPosts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://api.andrewslearning.com/api/posts/');
      const sortedPosts = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      
      // Extract featured post (most recent)
      if (sortedPosts.length > 0) {
        setFeaturedPost(sortedPosts[0]);
      }
      
      // Extract categories from posts
      const uniqueCategories = [...new Set(sortedPosts.map(post => 
        post.category || 'Uncategorized'
      ))];
      setCategories(['all', ...uniqueCategories]);
      
      setPosts(sortedPosts);
      setIsLoading(false);
    } catch (error) {
      console.error('Error retrieving posts:', error);
      setError(error.toString());
      setIsLoading(false);
    }
  };

  const handleDeletePost = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await axios.delete(`https://api.andrewslearning.com/api/posts/${postId}/`);
        const updatedPosts = posts.filter((post) => post.id !== postId);
        setPosts(updatedPosts);
        
        // Update featured post if necessary
        if (featuredPost && featuredPost.id === postId) {
          setFeaturedPost(updatedPosts[0] || null);
        }
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getReadTime = (content) => {
    // Approximation: average reading speed is 200-250 words per minute
    const words = content ? content.trim().split(/\s+/).length : 0;
    const minutes = Math.ceil(words / 200);
    return minutes > 0 ? `${minutes} min read` : '1 min read';
  };

  const getSortedAndFilteredPosts = () => {
    let filtered = [...posts];
    
    // Category filtering
    if (selectedCategory !== 'all') {
      filtered = filtered.filter(post => 
        (post.category || 'Uncategorized') === selectedCategory
      );
    }
    
    // Search query filtering
    if (searchQuery) {
      filtered = filtered.filter(post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (post.snippet && post.snippet.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }
    
    // Sorting
    if (sortBy === 'newest') {
      filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    } else if (sortBy === 'oldest') {
      filtered.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    } else if (sortBy === 'title') {
      filtered.sort((a, b) => a.title.localeCompare(b.title));
    }
    
    return filtered;
  };

  const filteredPosts = getSortedAndFilteredPosts();
  const regularPosts = featuredPost 
    ? filteredPosts.filter(post => post.id !== featuredPost.id) 
    : filteredPosts;

  return (
    <div className="blog-page">
      <div className="blog-header" ref={headerRef}>
        <div className="header-content">
          <h1>Our Blog</h1>
          <p>Insights, ideas, and perspectives to help you learn and grow</p>
        </div>
      </div>

      <div className="blog-container">
        {isLoading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Loading amazing content...</p>
          </div>
        ) : error ? (
          <div className="error-message">
            <h3>Oops! Something went wrong</h3>
            <p>{error}</p>
          </div>
        ) : (
          <>
            <div className="blog-controls">
              <div className="search-wrapper">
                <input
                  type="text"
                  placeholder="Search articles..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="search-bar"
                />
                <i className="search-icon">🔍</i>
              </div>
              
              <div className="filter-controls">
                <select 
                  value={selectedCategory} 
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="category-filter"
                >
                  {categories.map(category => (
                    <option key={category} value={category}>
                      {category === 'all' ? 'All Categories' : category}
                    </option>
                  ))}
                </select>
                
                <select 
                  value={sortBy} 
                  onChange={(e) => setSortBy(e.target.value)}
                  className="sort-filter"
                >
                  <option value="newest">Newest First</option>
                  <option value="oldest">Oldest First</option>
                  <option value="title">By Title</option>
                </select>
              </div>
            </div>

            {featuredPost && selectedCategory === 'all' && !searchQuery && (
              <div className="featured-post">
                <div className="featured-image">
                  <img 
                    src={featuredPost.image || 'https://via.placeholder.com/800x400?text=Featured+Article'} 
                    alt={featuredPost.title} 
                  />
                </div>
                <div className="featured-content">
                  <div className="post-meta">
                    <span className="post-category">{featuredPost.category || 'Uncategorized'}</span>
                    <span className="post-date">{formatDate(featuredPost.timestamp)}</span>
                    <span className="post-readtime">{getReadTime(featuredPost.content)}</span>
                  </div>
                  <h2 className="featured-title">
                    <Link to={`/posts/${featuredPost.id}`}>{featuredPost.title}</Link>
                  </h2>
                  <p className="featured-excerpt">{featuredPost.snippet}</p>
                  <div className="post-author">
                    <div className="author-avatar">
                      {featuredPost.author && featuredPost.author.username 
                        ? featuredPost.author.username.charAt(0).toUpperCase() 
                        : 'A'}
                    </div>
                    <span className="author-name">
                      {featuredPost.author && featuredPost.author.username ? featuredPost.author.username : 'Anonymous'}
                    </span>
                  </div>
                  <Link to={`/posts/${featuredPost.id}`} className="read-more-button">
                    Read Article
                  </Link>
                </div>
              </div>
            )}

            <div className="posts-grid">
              {regularPosts.length === 0 ? (
                <div className="no-posts-message">
                  <h3>No posts found</h3>
                  <p>Try adjusting your search or filters</p>
                </div>
              ) : (
                regularPosts.map((post) => (
                  <div key={post.id} className="post-card">
                    <div className="card-image-container">
                      <img 
                        src={post.image || 'https://via.placeholder.com/400x250?text=Blog+Post'} 
                        alt={post.title} 
                        className="post-image" 
                      />
                      <div className="post-category-tag">
                        {post.category || 'Uncategorized'}
                      </div>
                    </div>
                    <div className="post-content">
                      <div className="post-meta">
                        <span className="post-date">{formatDate(post.timestamp)}</span>
                        <span className="post-readtime">{getReadTime(post.content)}</span>
                      </div>
                      <h2 className="post-title">
                        <Link to={`/posts/${post.id}`}>{post.title}</Link>
                      </h2>
                      <p className="post-snippet">{post.snippet}</p>
                      <div className="post-footer">
                        <div className="post-author">
                          <div className="author-avatar">
                            {post.author && post.author.username 
                              ? post.author.username.charAt(0).toUpperCase() 
                              : 'A'}
                          </div>
                          <span className="author-name">
                            {post.author && post.author.username ? post.author.username : 'Anonymous'}
                          </span>
                        </div>
                        
                        {currentUser && post.author && post.author.username === currentUser.username && (
                          <button 
                            className="delete-button" 
                            onClick={() => handleDeletePost(post.id)}
                            aria-label="Delete post"
                          >
                            🗑️
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BlogList;
